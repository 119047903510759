import moment from 'moment-timezone';
import { useEffect, useState } from 'react';

const calculateTimeLeft = (startTime, endTime) => {
    const now = moment().tz('Asia/Kolkata');
    const start = moment.tz(startTime, 'Asia/Kolkata');
    const end = moment.tz(endTime, 'Asia/Kolkata');

    if (now.isBefore(start)) {
        const difference = start.diff(now);
        return {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60),
            phase: 'beforeStart',
        };
    } else if (now.isBetween(start, end)) {
        const difference = end.diff(now);
        return {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60),
            phase: 'ongoing',
        };
    } else {
        return {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
            phase: 'closed',
        };
    }
};

const Timer = ({ registrationStartTime, registrationEndTime, biddingStartTime, biddingEndTime, pageType }) => {
    const [registrationTimeLeft, setRegistrationTimeLeft] = useState(calculateTimeLeft(registrationStartTime, registrationEndTime));
    const [biddingTimeLeft, setBiddingTimeLeft] = useState(calculateTimeLeft(biddingStartTime, biddingEndTime));

    useEffect(() => {
        const timer = setInterval(() => {
            setRegistrationTimeLeft(calculateTimeLeft(registrationStartTime, registrationEndTime));
            setBiddingTimeLeft(calculateTimeLeft(biddingStartTime, biddingEndTime));
        }, 1000);

        return () => clearInterval(timer);
    }, [registrationStartTime, registrationEndTime, biddingStartTime, biddingEndTime]);

    const formatTimeLeft = (timeLeft) => {
        const days = timeLeft.days > 0 ? `${timeLeft.days}D:` : '';  // Only show days if there are days left
        const hours = `${String(timeLeft.hours).padStart(2, '0')}H:`;
        const minutes = `${String(timeLeft.minutes).padStart(2, '0')}M:`;
        const seconds = `${String(timeLeft.seconds).padStart(2, '0')}S`;

        return `${days}${hours}${minutes}${seconds}`;
    };

    return (
        <div className="">
            <div className="countdown">
                {pageType === 'live' && biddingTimeLeft.phase === 'ongoing' && (
                    <div className='row align-items-center graycolor'>
                        <div className='col-6 align-items-center' style={{ fontSize: '14px' }}>Sale Ends In</div>
                        <div className='col-6 auctiontime text-dark mt-1'>{formatTimeLeft(biddingTimeLeft)}</div>
                    </div>
                )}
                {pageType === 'upcoming' && registrationTimeLeft.phase === 'ongoing' && (
                    <>
                        <div>
                            <div className='row align-items-end justify-content-center graycolor d-flex'>
                                <div className='col-6 align-items-center' style={{ fontSize: '14px' }}>Sale Ends In</div>
                                <div className='col-6 auctiontime text-dark mt-1 me-1'>{formatTimeLeft(registrationTimeLeft)}</div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Timer;
