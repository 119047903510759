// import logo from './logo.svg';
import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import Home1 from './components/home/home1';
import Home2 from './components/home/home2';
import Home3 from './components/home/home3';
import Page404 from './components/home/404';
import About from './components/home/about';
import Auctiondetail from './components/home/auction_detail';
import Blog from './components/home/blog';
import Blogdetails from './components/home/blog_details';
import Contact from './components/home/contact';
import Dashboard from './components/home/dashboard';
import Faq from './components/home/faq';
import Joinmerchant from './components/home/join_merchant';
import Liveauction from './components/home/live_auction';
import Login from './components/home/login';
import Signup from './components/home/signup';
import Howworks from './components/home/how_works';
import ProtectedRoute from './components/ProtectedRoute';
import Payment from './components/home/payment';
import { setupTokenRefresh } from './api';
import VerifyOTP from './components/email_validation/verifyOtp';
import ResetPassword from './components/email_validation/resetPassword';
import Forgotpassword from './components/home/forgot_password';
import Verifyemailotp from './components/home/verify_email_otp';
import Resetpassword from './components/home/Resetpassword';
import Upcomingauction from './components/home/upcoming_auction';
import Closedauction from './components/home/closed_auction';
import { WalletProvider } from './WalletContext';
import { PaymentMethodProvider } from './paymentMethodContext';
import { HighestBidderProvider } from './HighestBidderContext';
import {BidProvider} from './bidcountcontext';

const isAuthenticated = () => !!localStorage.getItem('access_token');

const App = () => {
  const navigate = useNavigate();
  const [lastActivity, setLastActivity] = useState(Date.now());

  useEffect(() => {
    setupTokenRefresh();
    const handleActivity = () => setLastActivity(Date.now());
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);

    const interval = setInterval(() => {
      if (Date.now() - lastActivity > 30 * 60 * 1000) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        navigate('/');
      }
    }, 60 * 1000);

    return () => {
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      clearInterval(interval);
    };
  }, [lastActivity, navigate]);
  return (
    <div className="App">
       <HighestBidderProvider>
       <BidProvider>
      <WalletProvider>
      <PaymentMethodProvider>
        <Routes>
          <Route path='/' element={<Home1 />} />
          <Route path='/home' element={<Navigate to="/" />} />
          <Route path='/home2' element={<Home2 />} />
          <Route path='/home3' element={<Home3 />} />
          <Route path='/404' element={<Page404 />} />
          <Route path='/about-us' element={<About />} />
          <Route path='/auction-details/:id' element={<Auctiondetail />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/blog-details' element={<Blogdetails />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/faq' element={<Faq />} />
          <Route path='/join-merchant' element={<Joinmerchant />} />
          <Route path='/live-auction' element={<Liveauction />} />
          <Route path='/upcoming-auction' element={<Upcomingauction />} />
          <Route path='/closed-auction' element={<Closedauction />} />
          <Route path='/login' element={isAuthenticated() ? <Navigate to="/" /> : <Login />} />
          <Route path='/signup' element={isAuthenticated() ? <Navigate to="/" /> : <Home1 />} />
          <Route path='/how-it-works' element={<Howworks />} />
          <Route path='/forgot-password' element={<Forgotpassword />} />
          <Route path='/verify_otp' element={<Verifyemailotp />} />
          <Route path='/reset-password' element={<Resetpassword />} />
          <Route element={<ProtectedRoute />}>
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/payment' element={<Payment />} />
          </Route>
        </Routes>
      </PaymentMethodProvider>
      </WalletProvider>
      </BidProvider>
      </HighestBidderProvider>
    </div>
  );
}

export default App;
