import React from 'react';
import Header from '../header_footer/header';
import Howworksinnerbanner from '../how_works/how_works_inner_banner';
import Howworkschooseus from '../how_works/how_works_choose_us';
import Howworksaboutus from '../how_works/how_works_about_us';
import Footer from '../header_footer/footer';

const Howworks = () => {
  return (
    <div>
        <Header />
        <Howworksinnerbanner />
        <Howworkschooseus />
        {/* <Howworksaboutus /> */}
        <Footer />
    </div>
  )
}

export default Howworks