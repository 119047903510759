import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../api';
import * as XLSX from 'xlsx';
import Timer from '../registration_timer_components/Timer';
import Modal from 'react-modal';
import moment from 'moment-timezone';
import { formatDistanceToNow } from 'date-fns';
import { useWallet } from '../../WalletContext';
import { useLocation } from 'react-router-dom';
import { usePaymentMethod } from '../../paymentMethodContext';
import OrderTracking from './order_tracking';


const Auctiondetailinnerbanner = () => {
    const { id } = useParams();
    const [auction, setAuction] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [excelData, setExcelData] = useState(null);
    const [isRegistered, setIsRegistered] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [bidAmount, setBidAmount] = useState('');
    const { walletAmount, setWalletAmount } = useWallet();
    const [errorMessage, setErrorMessage] = useState('');
    const [highestBid, setHighestBid] = useState(null);
    const [bids, setBids] = useState([]);
    const [allbids, setallBids] = useState([]);
    const [userbids, setuserBids] = useState([]);
    const [highestBidder, setHighestBidder] = useState('');
    const { paymentMethod } = usePaymentMethod();
    const [isBidModalOpen, setIsBidModalOpen] = useState(false);
    const [bidCount, setBidCount] = useState(0);
    const [showOrderTracking, setShowOrderTracking] = useState(false);
    const handleCollectProduct = () => {
        setShowOrderTracking(true);
    };

    useEffect(() => {
        Modal.setAppElement('#root');

        const fetchAuction = async () => {
            try {
                const response = await api.get(`auction_detail/${id}/`);
                setAuction(response.data.auction);
                setHighestBid(response.data.highest_bid);
                setHighestBidder(response.data.highest_bidder);
                setLoading(false);
                console.log(response.data.excel_file);
                if (response.data.excel_file) {
                    fetchExcelData(response.data.excel_file);
                }
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        const fetchRegistrationStatus = async () => {
            try {
                const response = await api.get(`registration-status/${id}/`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                    },
                });
                setIsRegistered(response.data.is_registered);
            } catch (error) {
                console.error("Failed to fetch registration status:", error);
            }
        };

        const fetchHighestBid = async () => {
            try {
                const response = await api.get(`${id}/highest-bid/`);
                setHighestBid(response.data.total_bid);
                console.log('Highest Bid:', response.data.total_bid);
                setHighestBidder(response.data.first_name);

            } catch (error) {
                console.error("Failed to fetch highest bid:", error);
            }
        };

        const fetchUserBids = async () => {
            try {
                const response = await api.get(`${id}/bid/?type=user`); // Specify type as 'user'
                const bidsData = response.data;
                console.log('User Bids:', bidsData);
                setuserBids(bidsData);
                setBidCount(bidsData.length);
            } catch (error) {
                console.error('Failed to fetch user bids:', error);
            }
        };
        const fetchAllBids = async () => {
            try {
                const response = await api.get(`${id}/bid/?type=all`); // Specify type as 'all'
                const bidsData = response.data;
                console.log('All Bids:', bidsData);
                setallBids(bidsData);
            } catch (error) {
                console.error('Failed to fetch all bids:', error);
            }
        };

        fetchAuction();
        fetchRegistrationStatus();
        fetchUserBids();
        fetchAllBids();
        const interval = setInterval(() => {
            fetchHighestBid();
        }, 1000);
        return () => clearInterval(interval);
    }, [id]);

    const fetchExcelData = async (fileUrl) => {
        try {
            const response = await fetch(fileUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                },
                mode: 'cors'
            });

            if (response.ok) {
                const arrayBuffer = await response.arrayBuffer();
                const workbook = XLSX.read(arrayBuffer, { type: 'array' });
                const firstSheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[firstSheetName];
                const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                // Set the parsed Excel data to the state
                setExcelData(data);
            } else {
                throw new Error('Failed to fetch the Excel file');
            }
        } catch (error) {
            setError(error);
        }
    };

    const handleRegisterClick = () => {
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    // Handle wallet-based registration
    const handleWalletRegister = async () => {
        try {
            const response = await api.post(`${id}/register/`, {
                auction_name: auction.name,  // Pass auction name to backend
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                    'X-CSRFToken': getCookie('csrftoken'),
                }
            });
            if (response.status === 200) {
                setWalletAmount(response.data.wallet_amount || walletAmount);
                setIsRegistered(true);
                setIsModalOpen(false);
                setErrorMessage('Registration successful!');
            } else {
                setErrorMessage('Registration failed.');
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                // Handle insufficient funds error
                setErrorMessage('Insufficient amount in wallet, Please Topup.');
                setTimeout(() => {
                    setErrorMessage(null);
                }, 3000)
                // setIsModalOpen(false);
            } else {
                console.error('Registration failed:', error);
                setError(error);
            }
        }
    };

    // Handle Razorpay-based registration
    const handleRazorpayRegister = async () => {
        try {
            const response = await api.post(`register-bid/razorpay/${id}/`, {
                registration_price: auction.registration_price,
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                    'X-CSRFToken': getCookie('csrftoken'),
                }
            });
            const data = response.data;
            const options = {
                key: data.razorpay_key_id,
                amount: data.registration_price * 100,  // Convert to paisa
                currency: 'INR',
                name: 'Auction Registration',
                description: 'Register for Auction',
                order_id: data.order_id,
                handler: async function (paymentResult) {
                    await verifyRazorpayPayment(paymentResult);  // Verify Razorpay payment
                },
                prefill: {
                    name: data.user_name || '',
                    email: data.user_email || '',
                    contact: data.user_contact || '',
                },
                theme: {
                    color: '#3399cc',
                },
            };
            const rzp = new window.Razorpay(options);
            rzp.open();
        } catch (error) {
            console.error('Error creating Razorpay order:', error);
            setErrorMessage('Failed to initiate Razorpay payment.');
        }
    };

    // Handle "Register Now" action, selects based on payment method
    const handleRegisterNow = async () => {
        setErrorMessage(null);
        if (paymentMethod === 'wallet') {
            await handleWalletRegister();
        } else if (paymentMethod === 'razorpay') {
            await handleRazorpayRegister();
        }
    };

    const verifyRazorpayPayment = async (paymentResult) => {
        try {
            const response = await api.post(`register-bid/razorpay/verify/${id}/`, {
                razorpay_order_id: paymentResult.razorpay_order_id,
                razorpay_payment_id: paymentResult.razorpay_payment_id,
                razorpay_signature: paymentResult.razorpay_signature,
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                }
            });
            if (response.data.status === 'payment verified') {
                setIsRegistered(true);  // Mark the auction as registered
                setIsModalOpen(false);  // Close the modal
                setErrorMessage('Registration successful!');
            } else {
                setErrorMessage('Payment verification failed.');
            }
        } catch (error) {
            console.error('Error during payment verification:', error);
            setErrorMessage('Payment verification failed.');
        }
    };

    const fetchWalletAmount = async () => {
        try {
            const response = await api.get('wallet-amount/', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`
                }
            });
            setWalletAmount(response.data.wallet_amount);
        } catch (error) {
            console.error('Error fetching wallet amount:', error);
        }
    };

    const handleRazorpayBid = async () => {
        const token = localStorage.getItem('access_token');
        setError(null);
        // Validate bid amount
        if (bidAmount.trim() === '' || isNaN(bidAmount) || bidAmount <= 0) {
            setErrorMessage('Please enter a valid bid amount.');
            return;
        }
        try {
            // Call the backend API to create a Razorpay order for the bid
            const response = await api.post(`${id}/place-razor-bid/`,
                { bid_amount: bidAmount },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'X-CSRFToken': getCookie('csrftoken')
                    }
                });
            // Process successful bid creation
            const data = response.data;
            const options = {
                key: data.razorpay_key_id,
                amount: data.bid_amount * 100, // Convert to paisa
                currency: 'INR',
                name: "Bid Payment",
                order_id: data.order_id,
                description: "Place your bid on the auction",
                image: "https://example.com/your_logo.jpg",  // Update this with your logo
                prefill: {
                    name: data.user_name || '',
                    email: data.user_email || '',
                    contact: data.user_contact || '',
                },
                theme: {
                    color: "#F37254"
                },
                handler: function (response) {
                    verifyBidPayment(response);

                }
            };
            const rzp = new window.Razorpay(options);
            rzp.open();
        } catch (error) {
            // Capture and display error messages from the backend
            if (error.response) {
                const status = error.response.status;
                const backendError = error.response.data.error;
                if (status === 400) {
                    setErrorMessage(backendError || 'Bad request. Please check your input.');
                } else if (status === 403) {
                    setErrorMessage(backendError || 'You are not authorized to place a bid.');
                } else if (status === 500) {
                    setErrorMessage('An internal server error occurred. Please try again later.');
                } else {
                    setErrorMessage(backendError || 'An unexpected error occurred.');
                }
            } else {
                setErrorMessage('Network error. Please check your connection.');
            }
        }
    };

    // Function to verify the Razorpay payment after successful payment
    const verifyBidPayment = async (response) => {
        const token = localStorage.getItem('access_token');
        setError(null);
        try {
            const verifyResponse = await api.post('/razorpay/verify-payment/', {
                razorpay_order_id: response.razorpay_order_id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
                auction_id: id,
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'X-CSRFToken': getCookie('csrftoken')
                }
            });
            if (verifyResponse.data.status === 'payment verified') {
                setErrorMessage('Bid placed successfully!');
                setHighestBid(verifyResponse.data.highest_bid);
                // Optionally update UI (like updating the highest bid)
            } else {
                throw new Error(verifyResponse.data.error || 'Bid payment verification failed.');
            }
        } catch (error) {
            console.error('Error during bid payment verification:', error);
            setError(error.response?.data?.error || 'Payment verification failed.');
        }
    };

    // Helper function to get the CSRF token from cookies
    function getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    const handleWalletBid = async () => {
        try {
            const response = await api.post(`/${id}/bid/`, {
                bid_amount: bidAmount
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                }
            });
            if (response.status === 200) {
                setErrorMessage(response.data.message || "bid successfully")
                setWalletAmount(response.data.wallet_amount);
                setHighestBid(response.data.highest_bid.total_bid);
            } else {
                // Handle unexpected status codes
                setErrorMessage("An unexpected error occurred.");
            }
        } catch (error) {
            handleErrorMessage(error);
        }
    };

    const handleErrorMessage = (error) => {
        if (error.response && error.response.data && error.response.data.error) {
            const errorMessage = error.response.data.error;
            setErrorMessage(errorMessage);
        } else {
            setErrorMessage('An unexpected error occurred.');
        }
    };

    const handlePlaceBid = () => {
        if (!bidAmount || bidAmount <= 0 || isNaN(bidAmount)) {
            setErrorMessage('Please enter a valid amount greater than 0.');
            return;
        }
        if (paymentMethod === 'wallet') {
            handleWalletBid();
        } else if (paymentMethod === 'razorpay') {
            handleRazorpayBid();
        }
    };

    const handleYesClick = () => {
        handlePlaceBid();
        setIsBidModalOpen(false);
    };
    const handleNoClick = () => {
        setIsBidModalOpen(false);
    };


    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;
    if (!auction) {
        return <div>No auction details available.</div>;
    }

    const now = moment().tz('Asia/Kolkata');
    const registrationStarted = now.isAfter(moment.tz(auction.registration_starting_time, 'Asia/Kolkata'));
    const registrationEnded = now.isAfter(moment.tz(auction.registration_end_time, 'Asia/Kolkata'));
    const biddingStarted = now.isAfter(moment.tz(auction.bidding_start_time, 'Asia/Kolkata'));
    const biddingEnded = now.isAfter(moment.tz(auction.bidding_end_time, 'Asia/Kolkata'));

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '600px',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        },
    };

    const modalContentStyles = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
    };

    const modalHeaderStyles = {
        fontSize: '32px',
        fontWeight: 'bold',
    };

    const modalSubHeaderStyles = {
        fontSize: '16px',
        fontWeight: '400',
    }

    const modalProductInfoStyles = {
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
        width: '100%',
        marginTop: '20px',
    };

    const modalImageStyles = {
        width: '150px',
        height: '150px',
        objectFit: 'cover',
    };

    const modalDetailsStyles = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '10px',
    };

    const modalButtonStyles = {
        backgroundColor: '#28a745',
        color: '#fff',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: '700',
    };

    const closeButtonStyles = {
        position: 'absolute',
        right: '10px',
        backgroundColor: 'transparent',
        border: 'none',
        fontSize: '24px',
        cursor: 'pointer',
    };

    const renderBidDetails = () => {

        if (biddingStarted && !biddingEnded) {
            return (
                <div className='bid-details-div'>
                    <div className='mrp-auctionid-div'>
                        <h4 className='mrp'>MRP : <span>₹{auction.mrp}</span></h4>
                        <h4 className='auction-id'>Auction ID:OJK- <span>{auction.auction_id}</span></h4>
                    </div>
                    <Timer
                        biddingStartTime={auction.bidding_start_time}
                        biddingEndTime={auction.bidding_end_time}
                        pageType="live"
                    />
                    <div className='get-product-div'>
                        <img src="/assets/images/icons/truck-img.svg" alt='shipping' className='img-fluid' />
                        <p className='get-it'>Get it in 7 to 10 days</p>
                    </div>
                    <div className='top-bidder-div'>
                        <img src='/assets/images/bg/top-bidder-logo.png' className='top-bidder-logo' alt='top bidder' />
                        <div className='d-flex flex-column'>
                            <p>Top Bidder</p>
                            <h3>{highestBidder} - {highestBid}</h3>
                        </div>
                    </div>
                    <div className='row'>
                        {userbids.slice().reverse().map((bid, index) => (
                            <div className='col-4' key={index}>
                                {typeof bid.bid_amount === 'string' || typeof bid.bid_amount === 'number' ? (
                                    <p className='bid-count'>₹{bid.bid_amount}</p>
                                ) : (
                                    <p className='bid-count'>
                                        <img src="/assets/images/icons/bid-lock.svg" alt="Bid Locked" className="bid-lock-icon" />
                                    </p>
                                )}
                            </div>
                        ))}
                        {Array.from({ length: 3 - userbids.length }).map((_, index) => (
                            <div className='col-4' key={userbids.length + index}>
                                <p className='bid-count'>
                                    <img src='/assets/images/icons/bid-lock.svg' alt='bid-lock' />
                                </p>
                            </div>
                        ))}
                    </div>
                    {isRegistered ? (
                        <>
                            <input type='number'
                                value={bidAmount}
                                onChange={(e) => setBidAmount(e.target.value)}
                                className="form-control"
                                style={{ width: '500px', padding: '6px' }}
                                placeholder='₹ Enter bidding amount'
                            />
                            <button
                                type='button'
                                className='eg-btn btn--primary btn--sm place-bid-in-details'
                                onClick={() => {
                                    if (bidCount < 3) {
                                        setIsBidModalOpen(true);
                                        setBidCount(bidCount + 1); // Increment the bid count on successful bid
                                    }
                                }}
                                disabled={bidCount >= 3} // Disable button if bid count is 3 or more
                            >
                                Place bid {bidCount}/3
                            </button>
                        </>
                    ) : (
                        <p className="text-danger">This auction is not registered.</p>
                    )}
                </div>
            );
        } else if (registrationStarted && !registrationEnded) {
            return (
                <div className='bid-details-div'>
                    <div className='mrp-auctionid-div'>
                        <h4 className='mrp'>MRP : <span>₹{auction.mrp}</span></h4>
                        <h4 className='auction-id'>Auction ID:OJK- <span>{auction.auction_id}</span></h4>
                    </div>
                    <Timer
                        registrationStartTime={auction.registration_starting_time}
                        registrationEndTime={auction.registration_end_time}
                        pageType="upcoming"
                    />
                    <div className='get-product-div'>
                        <img src="/assets/images/icons/truck-img.svg" alt='shipping' className='img-fluid' />
                        <p className='get-it'>Get it in 7 to 10 days</p>
                    </div>
                    <div className='row'>
                        <div className='col-11'>
                            {/* Check if the user is registered */}
                            {isRegistered ? (
                                <button type='button' className='eg-btn w-100 pbbtn btn--sm btn--secondary' disabled>
                                    Registered
                                </button>
                            ) : (
                                <button type='button' className='eg-btn w-100 pbbtn btn--sm btn--primary' onClick={() => setIsModalOpen(true)}>
                                    Register
                                </button>
                            )}
                        </div>
                       
                        <div className="register-area col-1">
                            <div className="tooltip-container">
                                {/* Conditional image source based on registered users */}
                                <img className="registration-status" src={(() => {
                                    const registeredCount = auction.registered_users_count; // Assuming this is the count of registered users you get from the backend
                                    const minimumRequired = auction.minimum_required_registration;
                                    if (registeredCount >= minimumRequired + 1) {
                                        // More than required: display crowd_1.svg (green equivalent)
                                        return "/assets/images/bg/status_green.svg";
                                    } else if (registeredCount === minimumRequired) {
                                        // Exactly required: display crowd_2.svg (yellow equivalent)
                                        return "/assets/images/bg/status_yellow.svg";
                                    } else {
                                        // Less than required: display crowd_3.svg (red equivalent)
                                        return "/assets/images/bg/status_red.svg";
                                    }
                                })()} alt="Registration status" />
                                {/* Tooltip Text */}
                                <span className="tooltip-text">
                                    {(() => {
                                        const registeredCount = auction.registered_users_count;
                                        const minimumRequired = auction.minimum_required_registration;
                                        if (registeredCount >= minimumRequired + 1) {
                                            return "More than required participants registered";
                                        } else if (registeredCount === minimumRequired) {
                                            return "Minimum participants reached";
                                        } else {
                                            return "Not enough participants yet";
                                        }
                                    })()}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (biddingEnded) {
            if (showOrderTracking) {
                return <OrderTracking />;
            }
            return (
                <div className='bid-details-div'>
                    <div className='mrp-auctionid-div'>
                        <h4 className='mrp'>MRP : <span>₹{auction.mrp}</span></h4>
                        <h4 className='auction-id'>Auction ID:OJK- <span>{auction.auction_id}</span></h4>
                    </div>
                    <div className='top-bidder-div'>
                        <img src='/assets/images/bg/winner_pic.svg' className='top-celebrate-logo' alt='top bidder' />
                        <div className='d-flex flex-column'>
                            <p className='fw-bold graycolor mb-1'>Winner</p>
                            <h3>{highestBidder || "N/A"} - ₹ {highestBid.toLocaleString()}</h3>
                        </div>
                        <img src='/assets/images/bg/winner_pic.svg' className='top-celebrate-logo' alt='top bidder' />
                    </div>
                    <div className='get-product-div'>
                        <img src="/assets/images/icons/truck-img.svg" alt='shipping' className='img-fluid' />
                        <p className='get-it'>Get it in 7 to 10 days</p>
                    </div>
                    <button
                        type="button"
                        className="eg-btn btn--primary btn--sm place-bid-in-details"
                        onClick={handleCollectProduct}
                    >
                        Collect Product
                    </button>
                </div>
            );
        }
        return null;
    };

    return (
        <div>
            <div className="auction-details-section pt-120">
                <div className="container">
                    <div className="row g-4">
                        <h3 className='product-name'>{auction.name}</h3>
                        <div className="col-xl-6 col-lg-7 d-flex flex-row align-items-start justify-content-lg-start justify-content-center flex-md-nowrap flex-wrap gap-4">
                            <div className="tab-content mb-4 d-flex justify-content-lg-start justify-content-center wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".4s">
                                {Array.isArray(auction.image) && auction.image.length > 0 ? (
                                    auction.image.map((img, index) => (
                                        <div key={index} className={`tab-pane big-image fade ${index === 0 ? 'show active' : ''}`} id={`gallery-img${index + 1}`}>
                                            <div className='image-card border border-2'>
                                                <img src={img.image_url} alt={auction.name} className="img-fluid product_images" />
                                                <ul className="nav small-image-list flex-row wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".4s">
                                                    {auction.image.map((subImg, subIndex) => (
                                                        <li className="nav-item" key={subIndex}>
                                                            <div className="image-sub-card" id={`details-img${subIndex + 1}`} data-bs-toggle="pill" data-bs-target={`#gallery-img${subIndex + 1}`} aria-controls={`gallery-img${subIndex + 1}`}>
                                                                <img src={subImg.image_url} alt={auction.name} className="img-fluid image-sub-card" />
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>))
                                ) : (
                                    <p>No images available for this auction.</p>
                                )}
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-5">
                            <div className="product-details-right wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
                                {renderBidDetails()}
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className='row'>
                        <div className='col-6'>
                            {excelData && (
                                <div>
                                    <br />
                                    <h3 className='product-overview'>Product Overview</h3>
                                    <table className='mb-45' style={{ borderCollapse: 'collapse', width: '100%' }}>
                                        <thead>
                                            <tr>
                                                {/* Render table headers */}
                                                {excelData[0].map((header, index) => (
                                                    <th key={index} style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                                                        {header}
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* Render table rows */}
                                            {excelData.slice(1).map((row, rowIndex) => (
                                                <tr key={rowIndex} style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                                                    {row.map((cell, cellIndex) => (
                                                        <td key={cellIndex} style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                                                            {/* If cell is a link, display it as a clickable link */}
                                                            {typeof cell === 'string' && cell.startsWith('http') ? (
                                                                <a href={cell} target="_blank" rel="noopener noreferrer">{cell}</a>
                                                            ) : (
                                                                cell
                                                            )}
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                            <div className='mb-20 description'>
                                Product Description
                            </div>
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".2s" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                    <div className="describe-content">
                                        <p className="para" style={{ textAlign: 'left' }}>{auction.description}</p>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="pills-bid" role="tabpanel" aria-labelledby="pills-bid-tab">

                                </div>
                            </div>
                        </div>
                        <div className='col-6'>
                            <br />
                            <h3 className='product-overview'>Bidding History</h3>
                            <div className="bid-list-area">
                                <div className="bid-list-header">
                                    <div className="row bid-history-header">
                                        <div className="col-2">
                                            <strong>Bids</strong>
                                        </div>
                                        <div className="col-4">
                                            <strong>User</strong>
                                        </div>
                                        <div className="col-2 text-end">
                                            <strong>Amount</strong>
                                        </div>
                                        <div className="col-4 text-end">
                                            <strong>Time</strong>
                                        </div>
                                    </div>
                                </div>
                                <ul className="bid-list">
                                    {allbids && allbids.length > 0 ? (
                                        allbids.map((bid, index) => (
                                            
                                            <li
                                                key={index}
                                                style={{
                                                    backgroundColor: index % 2 === 0 ? "white" : "#E0FFEB",
                                                    listStyleType: "none",
                                                    padding: "10px",
                                                    margin: "0",
                                                }}
                                            >
                                                <div className="row d-flex align-items-center">
                                                    <div className="col-2">
                                                        {bid.bid_count === 1 ? "1st Bid" :
                                                            bid.bid_count === 2 ? "2nd Bid" :
                                                                bid.bid_count === 3 ? "3rd Bid" : `${bid.bid_count}th Bid`}
                                                    </div>
                                                    <div className="col-4">
                                                        <div className="bidder-content">
                                                            {bid.first_name}
                                                        </div>
                                                    </div>
                                                    <div className="col-2 text-end">
                                                        ₹{bid.bid_amount}
                                                    </div>
                                                    <div className="col-4 text-end">
                                                        <div className="bid-time">
                                                            <p>{formatDistanceToNow(new Date(bid.timestamp), { addSuffix: true })}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))
                                    ) : (
                                        <li style={{ listStyleType: "none" }}>No bids yet</li>
                                    )}
                                </ul>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Modal
                isOpen={isBidModalOpen}
                onRequestClose={() => setIsBidModalOpen(false)}
                contentLabel="Confirm Bid"
                style={customStyles}
            >
                <div style={modalContentStyles}>
                    <button onClick={() => setIsBidModalOpen(false)} style={closeButtonStyles}>&times;</button>
                    <div style={modalHeaderStyles}>Confirm Your Bid</div>
                    {errorMessage && <p className="text-danger">{errorMessage}</p>}
                    <p>Are you sure you want to place a bid of ₹{bidAmount}?</p>
                    {/* <p>{errorMessage || `If you already bid ₹${userPreviousBid} and now you bid ₹${bidAmount}, we reduce ₹${bidDifference} in the wallet.`}</p> */}
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <button onClick={handleYesClick} className="eg-btn btn--primary">Yes</button>
                        <button onClick={handleNoClick} className="eg-btn btn--secondary">No</button>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={handleModalClose}
                contentLabel="Register Auction"
                style={customStyles}
            >
                <div style={modalContentStyles}>
                    <button onClick={handleModalClose} style={closeButtonStyles}>&times;</button>
                    <div style={modalHeaderStyles}>Register Auction</div>
                    <div style={modalSubHeaderStyles}>Register Auction to claim your Product</div>
                    <div style={modalProductInfoStyles}>
                        {auction.image && auction.image[0] && (
                            <img
                                src={auction.image[0].image_url}
                                alt="Product"
                                style={modalImageStyles}
                            />
                        )}
                        <div style={modalDetailsStyles}>
                            <h3 style={{ fontSize: '24px', fontWeight: '700', marginBottom: '0' }}>{auction.name}</h3>
                            <p style={{ color: '#F2B200', margin: '0' }}>Time Left:</p>
                            <Timer
                                registrationStartTime={auction.registration_starting_time}
                                registrationEndTime={auction.registration_end_time}
                                biddingStartTime={auction.bidding_start_time}
                                biddingEndTime={auction.bidding_end_time}
                                index={0} // Only one timer in the modal, so index can be 0
                            />
                            <p style={{ fontWeight: '700', fontSize: '20px' }}>Registration Fee:  <span style={{ fontSize: '16px', background: '#DADADA', padding: '6px', borderRadius: '8px' }}>₹{auction.registration_price}</span></p>
                            {errorMessage && (
                                <p className='text-danger'>{errorMessage}</p>
                            )}
                        </div>
                    </div>
                    <div>
                        <button onClick={handleRegisterNow} style={modalButtonStyles}>Register Now</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
export default Auctiondetailinnerbanner;