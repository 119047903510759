import React from 'react';
import Header from '../header_footer/header';
import Aboutuscounter from '../join_merchant/about_us_counter';
import Innerbannersignup from '../join_merchant/inner_banner_signup';
import Footer from '../header_footer/footer';

const Joinmerchant = () => {
  return (
    <div>
        <Header />
        <Innerbannersignup />
        {/* <Aboutuscounter /> */}
        <Footer />
    </div>
  )
}

export default Joinmerchant