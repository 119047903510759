import React, { useState } from "react";
import api from "../../api";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ResetPassword = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const email = location.state?.email || '';

    const handleResetPassword = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        if (!newPassword || !confirmPassword) {
            setError('All fields are required.');
            return;
        }
        if (newPassword !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        try {
            const response = await api.post('reset_password/', { email, newPassword });
            setSuccess(response.data.detail);
            toast.success('Password reset successfully!');
            setTimeout(() => {
                navigate('/login');
            }, 3000); // Delay redirection to show the toast message
        } catch (error) {
            if (error.response && error.response.data) {
                setError(error.response.data.detail);
            } else {
                setError('Failed to reset password, please try again.');
            }
        }
    };

    return (
        <div className="reset-password-section pt-120 pb-120">
            <div className="container">
                <div className="row d-flex justify-content-center g-4">
                    <div className="col-xl-6 col-lg-8 col-md-10">
                        <div className="form-wrapper wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".2s">
                            <div className="form-title">
                                <h3>Reset Password</h3>
                                <p>Please enter your new password.</p>
                            </div>
                            <form className="w-100" onSubmit={handleResetPassword} noValidate>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-inner">
                                            <input
                                                type="password"
                                                placeholder="Enter New Password"
                                                value={newPassword}
                                                onChange={(e) => setNewPassword(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-inner">
                                            <input
                                                type="password"
                                                placeholder="Confirm New Password"
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                {error && <div className="error-message" style={{ color: 'red' }}>{error}</div>}
                                {/* {success && <div className="success-message">{success}</div>} */}
                                <button className="account-btn">Reset Password</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer /> 
        </div>
    );
};

export default ResetPassword;


