import React, { useEffect, useState } from 'react';
import api from '../../api';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import Timer from '../registration_timer_components/Timer';
import { usePaymentMethod } from '../../paymentMethodContext';
import { useWallet } from '../../WalletContext';
import Modal from 'react-modal';
import axios from 'axios';

const Live_auction = () => {
    const [details, setDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [registrationStatus, setRegistrationStatus] = useState({});
    const [highestBids, setHighestBids] = useState({});
    const [highestBidders, setHighestBidders] = useState({});
    const [bidAmount, setBidAmount] = useState({});
    const { paymentMethod, selectPaymentMethod } = usePaymentMethod(); // Updated destructuring
    const [isBidModalOpen, setIsBidModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [highestBid, setHighestBid] = useState(null);
    const { walletAmount, setWalletAmount } = useWallet();
    const [bidCount, setBidCount] = useState(0);
    const [userbids, setuserBids] = useState([]);
    const [selectedAuction, setSelectedAuction] = useState(null);
    const [userPreviousBid, setUserPreviousBid] = useState(0);
    const [bidDifference, setBidDifference] = useState(0);
    const [ConfirmationData, setConfirmationData] = useState(0);
    const [websockets, setWebsockets] = useState({});
    const isAuthenticated = () => !!localStorage.getItem('access_token');

    useEffect(() => {
        Modal.setAppElement('#root');
        const fetchDetails = async () => {
            try {
                const response = await api.get('auctions/?phase=live');
                console.log('Fetched auctions:', response.data); // Log the response
                setDetails(response.data);
                fetchHighestBidders(response.data);
                initializeWebsockets(response.data);
                if (isAuthenticated()) {
                    fetchRegistrationStatuses(response.data);
                    fetchUserBids(response.data); // Pass auction data here
                }
                fetchHighestBids(response.data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        const fetchUserBids = async (auctions) => {
            if (!Array.isArray(auctions)) {
                console.error('Expected an array of auctions, but received:', auctions);
                return; // Exit if auctions is not an array
            }

            try {
                const bidPromises = auctions.map(async (auction) => {
                    const response = await api.get(`${auction.auction_id}/bid/?type=user`);
                    return { auctionId: auction.auction_id, bids: response.data };
                });
                const bidsData = await Promise.all(bidPromises);

                // Create a map of auction IDs to their bids
                const allUserBids = {};
                const newBidCounts = {};
                bidsData.forEach(({ auctionId, bids }) => {
                    allUserBids[auctionId] = bids; // Store user bids by auction ID
                    newBidCounts[auctionId] = bids.length; // Set bid count for this auction
                });
                setuserBids(allUserBids); // Update state with user bids
                setBidCount(newBidCounts); // Update state with bid counts
            } catch (error) {
                console.error('Failed to fetch user bids:', error);
            }
        };
        fetchUserBids();
        const fetchRegistrationStatuses = async (auctions) => {
            try {
                const statuses = await Promise.all(
                    auctions.map(async (auction) => {
                        const response = await api.get(`registration-status/${auction.auction_id}/`, {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                            },
                        });
                        return { auctionId: auction.auction_id, isRegistered: response.data.is_registered };
                    })
                );
                const statusMap = {};
                statuses.forEach(status => {
                    statusMap[status.auctionId] = status.isRegistered;
                });
                setRegistrationStatus(statusMap);
            } catch (error) {
                console.error("Failed to fetch registration statuses:", error);
            }
        };

        const fetchHighestBids = async (auctions) => {
            try {
                const bidPromises = auctions.map(async (auction) => {
                    const response = await api.get(`${auction.auction_id}/highest-bid/`);
                    return { auctionId: auction.auction_id, highestBid: response.data.total_bid || 0 };
                });
                const bids = await Promise.all(bidPromises);
                const bidMap = {};
                bids.forEach(bid => {
                    bidMap[bid.auctionId] = bid.highestBid;
                });
                setHighestBids(bidMap);
            } catch (error) {
                console.error("Failed to fetch highest bids:", error);
            }
        };

        const fetchHighestBidders = async (auctions) => {
            try {
                const bidderPromises = auctions.map(async (auction) => {
                    const response = await api.get(`${auction.auction_id}/highest-bid/`);
                    return { auctionId: auction.auction_id, highestBidder: response.data.first_name };
                });
                const bidders = await Promise.all(bidderPromises);
                const bidderMap = {};
                bidders.forEach(bidder => {
                    bidderMap[bidder.auctionId] = bidder.highestBidder;
                });
                setHighestBidders(bidderMap);
            } catch (error) {
                console.error("Failed to fetch highest bidders:", error);
            }
        };

        fetchDetails();
        return () => {
            // Clean up WebSocket connections
            Object.values(websockets).forEach((socket) => {
                socket.close();
            });
        };
    }, []);
    const initializeWebsockets = (auctions) => {
        const sockets = {};
        auctions.forEach((auction) => {
            const socket = new WebSocket(
                `ws://localhost:8000/ws/auction/api/${auction.auction_id}/bid/`
            );

            socket.onmessage = (event) => {
                const data = JSON.parse(event.data);
                setHighestBids((prev) => ({
                    ...prev,
                    [auction.auction_id]: data.bid_amount,
                }));
                setHighestBidders((prev) => ({
                    ...prev,
                    [auction.auction_id]: data.bidder_name,
                }));
            };

            socket.onclose = () => {
                console.log(`WebSocket closed for auction ${auction.auction_id}`);
            };

            sockets[auction.auction_id] = socket;
        });

        setWebsockets(sockets);
    };
    const handleBidAmountChange = (auctionId, value) => {
        setBidAmount(prevBidAmount => ({
            ...prevBidAmount,
            [auctionId]: value,
        }));
    };

    const handleErrorResponse = (error) => {
        console.error(error.response);
        if (error.response && error.response.data && error.response.data.error) {
            setErrorMessage(error.response.data.error);
        } else {
            setErrorMessage('Network error. Please check your connection.');
        }
    };
    const handleBidClick = async (auction) => {
        if (!bidAmount[auction.auction_id] || bidAmount[auction.auction_id] <= 0) {
            setErrorMessage('Please enter a valid bid amount.');
            return;
        }

        if (isAuthenticated()) {
            try {
                // Fetch bid confirmation details from the backend
                const response = await api.post(`/${auction.auction_id}/confirm-bid/`, {
                    bid_amount: bidAmount[auction.auction_id]
                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('access_token')}`
                    }
                });

                const data = response.data;
                setConfirmationData(data);  // Store confirmation details
                setErrorMessage(data.message);
                setIsBidModalOpen(true);
                setSelectedAuction(auction);

            } catch (error) {
                setErrorMessage('Failed to confirm bid. Please try again.');
            }
        } else {
            setErrorMessage('You need to be logged in to place a bid.');
        }
    };
    const handlePaymentMethodChange = (e) => {
        const method = e.target.value;
        selectPaymentMethod(method); // Use selectPaymentMethod instead of setPaymentMethod
        localStorage.setItem('selectedPaymentMethod', method); // Save selected method in localStorage
    };

    const handlePlaceBid = async () => {
        if (paymentMethod === 'wallet') {
            handleWalletBid();
        } else if (paymentMethod === 'razorpay') {
            handleRazorpayBid();
        }
    };

    const handleWalletBid = async () => {
        try {
            const response = await api.post(`/${selectedAuction.auction_id}/bid/`, {
                bid_amount: bidAmount[selectedAuction.auction_id],
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`
                }
            });

            if (response.status === 200) {
                setErrorMessage(response.data.message || "Bid placed successfully");
                const newBidCount = bidCount + 1;
                setBidCount(newBidCount);
                localStorage.setItem('bidCount', newBidCount);
                setWalletAmount(response.data.wallet_amount);

                // Check if highest_bid exists before accessing total_bid
                if (response.data.highest_bid && response.data.highest_bid.total_bid) {
                    setHighestBid(response.data.highest_bid.total_bid);
                } else {
                    setHighestBid(null); // Handle cases where there's no highest bid
                }



                setTimeout(() => {
                    setErrorMessage(null);
                }, 3000);
            } else {
                setErrorMessage("An unexpected error occurred.");
            }
        } catch (error) {
            console.error(error);
            if (error.response && error.response.data && error.response.data.error) {
                setErrorMessage(error.response.data.error); // Display the specific error message from the server
            }
        }
    };

    const handleRazorpayBid = async () => {
        const token = localStorage.getItem('access_token');
        setErrorMessage(null);

        try {
            const response = await api.post(`${selectedAuction.auction_id}/place-razor-bid/`, {
                bid_amount: bidAmount[selectedAuction.auction_id],
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'X-CSRFToken': getCookie('csrftoken')
                }
            });

            const data = response.data;
            const options = {
                key: data.razorpay_key_id,
                amount: data.bid_amount * 100, // Convert to paisa
                currency: 'INR',
                name: "Bid Payment",
                order_id: data.order_id,
                description: "Place your bid on the auction",
                prefill: {
                    name: data.user_name || '',
                    email: data.user_email || '',
                    contact: data.user_contact || '',
                },
                theme: {
                    color: "#F37254"
                },
                handler: function (response) {
                    verifyBidPayment(response);
                }
            };

            const rzp = new window.Razorpay(options);
            rzp.open();
        } catch (error) {
            console.error(error);
            if (error.response && error.response.data && error.response.data.error) {
                setErrorMessage(error.response.data.error); // Display the specific error message from the server
            }
        }
    };

    const verifyBidPayment = async (response) => {
        const token = localStorage.getItem('access_token');

        try {
            const verifyResponse = await api.post('/razorpay/verify-payment/', {
                razorpay_order_id: response.razorpay_order_id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
                auction_id: selectedAuction.auction_id,
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'X-CSRFToken': getCookie('csrftoken')
                }
            });

            if (verifyResponse.data.status === 'payment verified') {
                setErrorMessage('Bid placed successfully!');
                setHighestBid(verifyResponse.data.highest_bid);
                const newBidCount = bidCount + 1;
                setBidCount(newBidCount);
                localStorage.setItem('bidCount', newBidCount);
            } else {
                throw new Error('Bid payment verification failed.');
            }
        } catch (error) {
            console.error(error);
            setErrorMessage('Payment verification failed.');
        }
    };

    const handleYesClick = () => {
        handlePlaceBid();  // Place the bid after confirmation
        setIsBidModalOpen(false);
    };

    const handleNoClick = () => {
        setIsBidModalOpen(false);
    };

    function getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '600px',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        },
    };

    const modalContentStyles = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
    };

    const modalHeaderStyles = {
        fontSize: '32px',
        fontWeight: 'bold',
    };

    const modalSubHeaderStyles = {
        fontSize: '16px',
        fontWeight: '400',
    }

    const modalProductInfoStyles = {
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
        width: '100%',
        marginTop: '20px',
    };

    const modalImageStyles = {
        width: '150px',
        height: '150px',
        objectFit: 'cover',
    };

    const modalDetailsStyles = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '10px',
    };

    const modalButtonStyles = {
        backgroundColor: '#28a745',
        color: '#fff',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: '700',
    };

    const closeButtonStyles = {
        position: 'absolute',
        right: '10px',
        backgroundColor: 'transparent',
        border: 'none',
        fontSize: '24px',
        cursor: 'pointer',
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <div>
            <div className="live-auction pt-50 pb-120">
                <img alt="" src="assets/images/bg/section-bg.png" className="img-fluid section-bg" />
                <div className="container position-relative">
                    <img alt="" src="assets/images/bg/dotted1.png" className="dotted1" />
                    <img alt="" src="assets/images/bg/dotted1.png" className="dotted2" />
                    <div className="row d-flex justify-content-center">
                        <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6">
                            <div className="section-title1">
                                <h2>Live Auction</h2>
                                <p className="mb-0">Explore on the world's best & largest Bidding marketplace with our beautiful Bidding products. We want to be a part of your smile, success and future growth.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row gy-4 mb-60 d-flex justify-content-center">
                        {details.map((auction, index) => {
                            const biddingOngoing = moment().tz('Asia/Kolkata').isBetween(moment.tz(auction.bidding_start_time, 'Asia/Kolkata'), moment.tz(auction.bidding_end_time, 'Asia/Kolkata'));
                            if (!biddingOngoing) return null; // Only display if bidding is ongoing
                            const highestBidder = highestBidders[auction.auction_id];
                            const highestBid = highestBids[auction.auction_id];
                            return (
                                <div key={auction.auction_id} className="col-lg-3 col-md-6 col-sm-12">
                                    <div data-wow-duration="1.5s" data-wow-delay={`${0.2 * (index + 1)}s`} className="eg-card auction-card1 wow animate fadeInDown">
                                        <div className="auction-img mt-2 mb-3">
                                            {auction.image && auction.image.length > 0 ? (
                                                <img alt="" src={auction.image[0].image_url} />
                                            ) : (
                                                <img alt="" src="assets/images/bg/live-auc1.png" />
                                            )}
                                            <div className='share-area'>
                                                <ul className="social-icons d-flex">
                                                    <li><a href={`https://www.facebook.com/sharer/sharer.php?u=http://alpha.ojaak.com/auction-details/${auction.auction_id}`}><i className="bx bxl-facebook"></i></a></li>
                                                    <li><a href={`https://twitter.com/intent/tweet?url=http://alpha.ojaak.com/auction-details/${auction.auction_id}`}><i className="bx bxl-twitter"></i></a></li>
                                                    <li><a href={`https://pinterest.com/pin/create/button/?url=http://alpha.ojaak.com/auction-details/${auction.auction_id}`}><i className="bx bxl-pinterest"></i></a></li>
                                                    <li><a href={`https://www.instagram.com/?url=http://alpha.ojaak.com/auction-details/${auction.auction_id}`}><i className="bx bxl-instagram"></i></a></li>
                                                </ul>
                                                <div>
                                                    <div className="share-btn"><i className='bx bxs-share-alt'></i></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="auction-content ">
                                            <h4>
                                                {isAuthenticated() ? (
                                                    <Link to={`/auction-details/${auction.auction_id}`}>{auction.name}</Link>
                                                ) : (
                                                    <Link to="/login">{auction.name}</Link>
                                                )}
                                            </h4>
                                            <div className='d-flex justify-content-between'>
                                                <div className='graycolor justify-self-start d-flex'>MRP ₹{auction.mrp}</div>
                                                <div className=''>Auction ID : OJK-{auction.auction_id}</div>

                                            </div>
                                            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                                            <div className='row mt-3 d-flex align-items-center liveauction'>
                                                <div className='col-12 flex-column align-items-center'>
                                                    <Timer
                                                        biddingStartTime={auction.bidding_start_time}
                                                        biddingEndTime={auction.bidding_end_time}
                                                        pageType="live"
                                                    />
                                                </div>
                                                <hr style={{ marginTop: '8px', marginBottom: '0px' }}></hr>
                                                <div className='flex-column align-items-center'>
                                                    <div className='fw-bold align-items-center pb-2 graycolor'>Top Bidder</div>
                                                    <div className='fw-normal text-dark text-balance d-flex justify-content-center align-items-center'>
                                                        {highestBidder} - {highestBid}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="auction-card-bttm">
                                                {isAuthenticated() ? (
                                                    registrationStatus[auction.auction_id] ? (
                                                        <>
                                                            <span
                                                                style={{
                                                                    fontSize: '1rem', // Adjust the size of the rupee symbol
                                                                    marginRight: '4px', // Add space between the symbol and input
                                                                    fontWeight: 'bold', // Optional: Make the symbol bold
                                                                    color: '#000', // Optional: Set color for the symbol
                                                                }}
                                                            >
                                                                ₹
                                                            </span><input
                                                                type="number"
                                                                name="amount"
                                                                className="bid-amount"
                                                                placeholder="enter bid amount"
                                                                value={bidAmount[auction.auction_id] || ''}
                                                                onChange={(e) => handleBidAmountChange(auction.auction_id, e.target.value)}
                                                            />

                                                            <button
                                                                type="button"
                                                                className="eg-btn btn--primary btn--sm"
                                                                onClick={() => handleBidClick(auction)}
                                                                disabled={bidCount[auction.auction_id] >= 3} // Use auction-specific bid count
                                                            >
                                                                {bidCount[auction.auction_id] || 0}/3
                                                            </button>
                                                        </>
                                                    ) : (
                                                        <p className="text-danger">You are not registered for this auction</p>
                                                    )
                                                ) : (
                                                    <Link to={`/login`} className="eg-btn btn--primary btn--sm pbbtn1">Log In</Link>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-4 text-center">
                            <Link to="/live-auction" className="eg-btn btn--primary btn--md mx-auto">View All</Link>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                isOpen={isBidModalOpen}
                onRequestClose={() => setIsBidModalOpen(false)}
                contentLabel="Confirm Bid"
                style={customStyles}
            >
                <div>
                    <h4>Select Payment Method:</h4>
                    <div>
                        <input
                            type="radio"
                            id="wallet"
                            name="paymentMethod"
                            value="wallet"
                            checked={paymentMethod === 'wallet'}
                            onChange={handlePaymentMethodChange}
                        />
                        <label htmlFor="wallet">Pay with Wallet</label>
                    </div>
                    <div>
                        <input
                            type="radio"
                            id="razorpay"
                            name="paymentMethod"
                            value="razorpay"
                            checked={paymentMethod === 'razorpay'}
                            onChange={handlePaymentMethodChange}
                        />
                        <label htmlFor="razorpay">Pay with Razorpay</label>
                    </div>
                </div>
                <div style={modalContentStyles}>
                    <button onClick={() => setIsBidModalOpen(false)} style={closeButtonStyles}>&times;</button>
                    <div style={modalHeaderStyles}>Confirm Your Bid</div>
                    <p>Are you sure you want to place a bid of ₹{bidAmount[selectedAuction?.auction_id]}?</p>


                    <p>{errorMessage || `If you already bid ₹${userPreviousBid} and now you bid ₹${bidAmount}, we reduce ₹${bidDifference} in the wallet.`}</p>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <button onClick={handleYesClick} className="eg-btn btn--primary">Yes</button>
                        <button onClick={handleNoClick} className="eg-btn btn--secondary">No</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
export default Live_auction;