import React from 'react';
import Header from '../header_footer/header';
import Dashboardsection from '../dashboard/dashboard_section';
import Footer from '../header_footer/footer';

const Dashboard = () => {
    return (
        <div>
            <Header />
            <Dashboardsection />
            <Footer />
        </div>
    )
}

export default Dashboard