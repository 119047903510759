import React from 'react';
import Header from '../header_footer/header';
import Faqaboutuscounter from '../faq/about_us_counter';
import Innerbannerfaqsection from '../faq/inner_banner_faq_section';
import Footer from '../header_footer/footer';

const Faq = () => {
    return (
        <div>
            <Header />
            <Innerbannerfaqsection />
            {/* <Faqaboutuscounter /> */}
            <Footer />
        </div>
    )
}

export default Faq