import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import api, { fetchCsrfToken } from '../../api';
import { validateEmail, validatePassword } from '../email_validation/validation';

const Innerbannerlogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const navigate = useNavigate();

    // On component mount, check if credentials are stored in local storage
    useEffect(() => {
        const storedEmail = localStorage.getItem('rememberMeEmail');
        const storedPassword = localStorage.getItem('rememberMePassword');
        const storedRememberMe = localStorage.getItem('rememberMe') === 'true';

        if (storedRememberMe) {
            setEmail(storedEmail || '');
            setPassword(storedPassword || '');
            setRememberMe(storedRememberMe);
        }
    }, []);

    const handleLogin = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');
        setEmailError('');
        setPasswordError('');

        let valid = true;
        if (!email) {
            setEmailError('This field is required.');
            valid = false;
        } else if (!validateEmail(email)) {
            setEmailError('Email must be a valid email address.');
            valid = false;
        }
        if (!password) {
            setPasswordError('This field is required.');
            valid = false;
        }
        if (!valid) {
            return;
        }

        try {
            await fetchCsrfToken();
            const response = await api.post('login/', {
                username: email,
                password
            });

            // Save tokens in local storage
            localStorage.setItem('access_token', response.data.access);
            localStorage.setItem('refresh_token', response.data.refresh);

            // Store email and password if "Remember Me" is checked
            if (rememberMe) {
                localStorage.setItem('rememberMeEmail', email);
                localStorage.setItem('rememberMePassword', password);
                localStorage.setItem('rememberMe', 'true');
            } else {
                // Clear stored credentials if "Remember Me" is unchecked
                localStorage.removeItem('rememberMeEmail');
                localStorage.removeItem('rememberMePassword');
                localStorage.removeItem('rememberMe');
            }

            setSuccess('Login successful!');
            navigate("/home");
        } catch (error) {
            if (error.response && error.response.data) {
                const errorData = error.response.data;
                if (errorData.detail === 'Invalid credentials') {
                    setPasswordError('Invalid credentials, please try again.');
                } else if (errorData.detail === 'User is not active. Please verify your email.') {
                    setError('User is not active. Please verify your email.');
                } else {
                    setError('Login failed! Please check your credentials and try again.');
                }
            } else {
                setError('Login failed! Please check your credentials and try again.');
            }
        }
    };

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    return (
        <div>
            <div className="login-section pt-50 pb-120">
                <img alt="" src="assets/images/bg/section-bg.png" className="img-fluid section-bg-top" />
                <img alt="" src="assets/images/bg/section-bg.png" className="img-fluid section-bg-bottom" />
                <div className="container">
                    <div className="row d-flex justify-content-center g-4">
                        <div className="col-xl-6 col-lg-8 col-md-10">
                            <div className="form-wrapper wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".2s">
                                <div className="form-title">
                                    <h3>Log In</h3>
                                    <p>New Member? <Link to="/">Sign up here</Link></p>
                                </div>
                                <form className="w-100" onSubmit={handleLogin} noValidate>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-inner">
                                                <input
                                                    type="email"
                                                    placeholder="Enter Your Email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    required
                                                />
                                                {emailError && <div className="error-message" style={{ color: 'red' }}>{emailError}</div>}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-inner">
                                                <input
                                                    type={isPasswordVisible ? "text" : "password"}
                                                    name="password"
                                                    id="password"
                                                    placeholder="Password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    required
                                                />
                                                <i
                                                    className={`bi ${isPasswordVisible ? "bi-eye" : "bi-eye-slash"}`}
                                                    id="togglePassword"
                                                    onClick={togglePasswordVisibility}
                                                    style={{ cursor: 'pointer' }}
                                                ></i>
                                                {passwordError && <div className="error-message" style={{ color: 'red' }}>{passwordError}</div>}
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-lg-6 col-md-6 col-sm-12 d-flex">
                                                <span className='rembermecheckbox d-flex'>
                                                    <input 
                                                        type="checkbox" 
                                                        checked={rememberMe} 
                                                        onChange={(e) => setRememberMe(e.target.checked)} 
                                                    />
                                                </span>
                                                <span className='ms-2 d-flex align-items-center rembermechecktext'>
                                                    Remember Me
                                                </span>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-lg-end align-items-center">
                                                <div className="form-agreement form-inner flex-wrap">
                                                    <Link to="/forgot-password" className="forgot-pass">Forgotten Password</Link>
                                                </div>
                                            </div>
                                        </div>

                                        {error && <div className="error-message" style={{ color: 'red' }}>{error}</div>}
                                        {success && <div className="success-message">{success}</div>}
                                        <button className="account-btn">Log in</button>
                                    </div>
                                </form>
                                <div className="form-policy-area">
                                    <p>By clicking the "Log in" button, you agree to Ojaak's <a href='/home2/' target="_blank" className='terms-policy'>Terms & Conditions</a> & <a href="home3" target="_blank" className='terms-policy'>Privacy Policy.</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Innerbannerlogin;
