import React from 'react'
import Header from '../header_footer/header';
import Footer from '../header_footer/footer';
import Aboutchooseus from '../about/about_choose_us';
import Aboutsection from '../about/about_section';
import Abouttestimonial from '../about/about_testimonial';
import Aboutuscounter from '../about/about_us_counter';


const About = () => {
    return (
        <div>
            <Header />
            <Aboutsection />
            <Aboutchooseus />
            <Abouttestimonial />
            <Aboutuscounter />
            <Footer />
        </div>
    )
}
export default About