import Footer from "../header_footer/footer"
import Header from "../header_footer/header"
import ForgotPassword from "../email_validation/forgotPassword"

const Forgotpassword = () => {
    return (
        <div>
            <Header />
            <ForgotPassword />
            <Footer />
        </div>
    )
}
export default Forgotpassword;