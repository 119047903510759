import React from 'react'
import Header from '../header_footer/header';
import Pagenotfound from '../404/404';
import Footer from '../header_footer/footer';

const Page404 = () => {
    return (
        <div>
            <Header />
            <Pagenotfound />
            <Footer />
        </div>
    )
}

export default Page404