import React from 'react';
import Header from '../header_footer/header';
import Blog_about_us from '../blog/blog_about_us';
import Blogsectioninnerbanner from '../blog/blog_section_inner_banner';
import Footer from '../header_footer/footer';

const Blog = () => {
    return (
        <div>
            <Header />
            <h1 className='dummy' >Website under construction</h1>
            <br/>
            {/* <Blogsectioninnerbanner /> */}
            {/* <Blog_about_us /> */}
            <Footer />
        </div>
    )
}
export default Blog