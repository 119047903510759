import React, { useEffect, useState } from 'react';
import api from '../../api';
import Timer from '../registration_timer_components/Timer';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';

const Closedauctionsection = () => {
    const [details, setDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [registrationStatus, setRegistrationStatus] = useState({});
    const isAuthenticated = () => !!localStorage.getItem('access_token');

    useEffect(() => {
        const fetchDetails = async () => {
            try {
                const response = await api.get('auctions/?phase=closed');
                setDetails(response.data);
                setLoading(false);
                if (isAuthenticated()) {
                    fetchRegistrationStatuses(response.data);
                }
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        const fetchRegistrationStatuses = async (auctions) => {
            try {
                const statuses = await Promise.all(
                    auctions.map(async (auction) => {
                        const response = await api.get(`registration-status/${auction.auction_id}/`, {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                            },
                        });
                        return { auctionId: auction.auction_id, isRegistered: response.data.is_registered };
                    })
                );
                const statusMap = {};
                statuses.forEach(status => {
                    statusMap[status.auctionId] = status.isRegistered;
                });
                setRegistrationStatus(statusMap);
            } catch (error) {
                console.error("Failed to fetch registration statuses:", error);
            }
        };

        fetchDetails();
    }, []);

    
    

    return (
        <div>
            <div className="live-auction pt-50 pb-120">
                <img alt="" src="assets/images/bg/section-bg.png" className="img-fluid section-bg" />
                <div className="container position-relative">
                    <img alt="" src="assets/images/bg/dotted1.png" className="dotted1" />
                    <img alt="" src="assets/images/bg/dotted1.png" className="dotted2" />
                    <div className="row d-flex justify-content-center">
                        <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6">
                            <div className="section-title1">
                                <h2>Closed Auction</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row gy-4 mb-60 d-flex justify-content-center">
                        {details.slice().map((auction, index) => {
                            const biddingEnded = moment().tz('Asia/Kolkata').isAfter(moment.tz(auction.bidding_end_time, 'Asia/Kolkata'));
                            if (!biddingEnded) return null; // Only display if bidding has ended

                            const isRegistered = registrationStatus[auction.auction_id];
                            return (
                                <div key={auction.auction_id} className="col-lg-3 col-md-6 col-sm-6">
                                    <div data-wow-duration="1.5s" data-wow-delay={`${0.2 * (index + 1)}s`} className="eg-card auction-card1 wow animate fadeInDown">
                                        <div className="auction-img">
                                            {auction.image && auction.image.length > 0 ? (
                                                <img alt="" src={auction.image[0].image_url} />
                                            ) : (
                                                <img alt="" src="assets/images/bg/live-auc1.png" />
                                            )}
                                            <Timer
                                                biddingStartTime={auction.bidding_start_time}
                                                biddingEndTime={auction.bidding_end_time}
                                                pageType="closed"
                                            />
                                        </div>
                                        <div className="auction-content mt-3">
                                            <h4><Link to={`/auction-details/${auction.auction_id}`}>{auction.name}</Link></h4>
                                            <div className='graycolor justify-self-start d-flex'>Bidding Price : ₹{auction.mrp}</div>
                                            <div className='row mt-3 d-flex align-items-center liveauction'>
                                                <div className='col-7 flex-column align-items-center border-endline'>
                                                    <div className='align-items-center pb-2 graycolor'>Auction Ended</div>
                                                    <div className='auctiontime text-dark '>
                                                        <div className='d-flex justify-content-center '>00<span className='me-xl-2 me-sm-0'>h:</span>00<span className='me-xl-2 me-sm-0'>m:</span>00<span className='me-xl-2 me-sm-0'>s</span></div>
                                                    </div>
                                                </div>
                                                <div className='col-5 flex-column align-items-center'>
                                                    <div className='align-items-center pb-2 graycolor'>Top Bid</div>
                                                    <div className='fw-bold text-dark text-balance d-flex justify-content-center align-items-center'>Balaji</div>
                                                </div>
                                            </div>
                                            <div className="auction-card-bttm row">
                                                {isAuthenticated() && (
                                                    <div className="col-10 ">
                                                        <Link
                                                            className={`eg-btn w-100 closedbtn btn--sm ${biddingEnded ? 'disabled-button' : ''}`}
                                                            disabled={biddingEnded}
                                                        >Closed</Link>
                                                    </div>
                                                )}
                                                <div className="share-area col-2">
                                                    <ul className="social-icons d-flex">
                                                        <li><a href={`https://www.facebook.com/sharer/sharer.php?u=http://alpha.ojaak.com/auction-details/${auction.auction_id}`}><i className="bx bxl-facebook"></i></a></li>
                                                        <li><a href={`https://twitter.com/intent/tweet?url=http://alpha.ojaak.com/auction-details/${auction.auction_id}`}><i className="bx bxl-twitter"></i></a></li>
                                                        <li><a href={`https://pinterest.com/pin/create/button/?url=http://alpha.ojaak.com/auction-details/${auction.auction_id}`}><i className="bx bxl-pinterest"></i></a></li>
                                                        <li><a href={`https://www.instagram.com/?url=http://alpha.ojaak.com/auction-details/${auction.auction_id}`}><i className="bx bxl-instagram"></i></a></li>
                                                    </ul>
                                                    <div>
                                                        <div className="share-btn"><i className='bx bxs-share-alt'></i></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    {/* <div className="row">
                        <nav className="pagination-wrap">
                            <ul className="pagination d-flex justify-content-center gap-md-3 gap-2">
                                <li className="page-item">
                                    <a className="page-link" href="#" tabIndex={-1}>Prev</a>
                                </li>
                                <li className="page-item"><a className="page-link" href="#">01</a></li>
                                <li className="page-item active" aria-current="page">
                                    <a className="page-link" href="#">02</a></li>
                                <li className="page-item"><a className="page-link" href="#">03</a></li>
                                <li className="page-item">
                                    <a className="page-link" href="#">Next</a>
                                </li>
                            </ul>
                        </nav>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default Closedauctionsection;

