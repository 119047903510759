import React from 'react';
import Header from '../header_footer/header';
import Footer from '../header_footer/footer';
import PaymentForm from '../payment/payment_form';
 
const Payment = () => {
  return (
    <div>
        <Header />
        <PaymentForm />
        <Footer />
    </div>
  )
}
 
export default Payment