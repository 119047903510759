import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import api, { fetchCsrfToken } from '../../api';
import { validateEmail, validateName, validatePassword } from '../email_validation/validation';
import OTPPopup from '../email_validation/otpEmailValidation';

const Indexcontent = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [termsError, setTermsError] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [showOtpPopup, setShowOtpPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('access_token');
        if (token) {
            navigate('/');
        } else {
            fetchCsrfToken();
        }
    }, [navigate]);

    const handleSignup = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');
        setFirstNameError('');
        setLastNameError('');
        setEmailError('');
        setPasswordError('');
        setTermsError('');
        setLoading(true);

        if (otpSent) {
            setShowOtpPopup(true);
            setLoading(false);
            return;
        }
        let valid = true;
        if (!firstName) {
            setFirstNameError('This field is required.');
            valid = false;
        } else if (!validateName(firstName)) {
            setFirstNameError('First name must contain only alphabetical characters.');
            valid = false;
        }

        if (!lastName) {
            setLastNameError('This field is required.');
            valid = false;
        } else if (!validateName(lastName)) {
            setLastNameError('Last name must contain only alphabetical characters.');
            valid = false;
        }

        if (!email) {
            setEmailError('This field is required.');
            valid = false;
        } else if (!validateEmail(email)) {
            setEmailError('Email must be a valid email address.');
            valid = false;
        }

        if (!password) {
            setPasswordError('This field is required.');
            valid = false;
        } else if (!validatePassword(password)) {
            setPasswordError('Password must be 8-15 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character.');
            valid = false;
        }

        if (!termsAccepted) {
            setTermsError('You must agree to the Terms & Policy.');
            valid = false;
        }

        if (!valid) {
            setLoading(false);
            return;
        }

        try {
            const response = await api.post('signup/', {
                username: email,
                email,
                password,
                first_name: firstName,
                last_name: lastName,
            });

            if (response.data.detail === 'OTP resent to your email. Please verify to proceed.') {
                setOtpSent(true);
                setSuccess(response.data.detail);
                setShowOtpPopup(true);
            } else {
                setOtpSent(true);
                setSuccess('Please check your email for the OTP.');
                setShowOtpPopup(true);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setShowOtpPopup(false);
            if (error.response && error.response.data) {
                const errorData = error.response.data;
                if (errorData.detail === 'You are already logged in.') {
                    setError('You are already logged in.');
                } else if (errorData.detail === 'OTP resent to your email.') {
                    setOtpSent(true);
                    setSuccess('OTP resent to your email. Please check your email.');
                    setShowOtpPopup(true);
                } else if (errorData.detail === 'Given email already exists.') {
                    setEmailError('Given email already exists.');
                } else {
                    setFirstNameError(errorData.first_name ? errorData.first_name[0] : '');
                    setLastNameError(errorData.last_name ? errorData.last_name[0] : '');
                    setEmailError(errorData.email ? errorData.email[0] : '');
                    setPasswordError(errorData.password ? errorData.password[0] : '');
                    if (!errorData.first_name && !errorData.last_name && !errorData.email && !errorData.password) {
                        setError(errorData.detail || 'Signup failed! Please try again.');
                    }
                }
            } else {
                setError('Signup failed! Please try again. ' + (error.message || ''));
            }
        }
    };

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    const handleOTPSuccess = () => {
        setShowOtpPopup(false);
        navigate('/login');
    };

    return (
        <div>
            <div className="home-signup-section pt-50 pb-120">
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-xl-6 col-lg-4 col-md-2">
                            <img alt="" src="assets/images/bg/signup_homepage.svg" />
                        </div>
                        <div className="col-xl-6 col-lg-8 col-md-10">
                            <div className="form-wrapper wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".2s">
                                <div className="form-title">
                                    <h3>Sign Up</h3>
                                    <p>Do you already have an account? <Link to="/login">Log in here</Link></p>
                                </div>
                                <form className="w-100" onSubmit={handleSignup} noValidate>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-inner">
                                                <input
                                                    type="text"
                                                    placeholder="First Name"
                                                    value={firstName}
                                                    onChange={(e) => setFirstName(e.target.value)}
                                                    required
                                                    aria-invalid={!!firstNameError}
                                                />
                                            </div>
                                            {firstNameError && <div className="error-message" style={{ color: 'red' }}>{firstNameError}</div>}
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-inner">
                                                <input
                                                    type="text"
                                                    placeholder="Last Name"
                                                    value={lastName}
                                                    onChange={(e) => setLastName(e.target.value)}
                                                    required
                                                    aria-invalid={!!lastNameError}
                                                />
                                            </div>
                                            {lastNameError && <div className="error-message" style={{ color: 'red' }}>{lastNameError}</div>}
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-inner">
                                                <input
                                                    type="email"
                                                    placeholder="Enter Your Email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    required
                                                    aria-invalid={!!emailError}
                                                />
                                            </div>
                                            {emailError && <div className="error-message" style={{ color: 'red' }}>{emailError}</div>}
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-inner">
                                                <input
                                                    type={isPasswordVisible ? "text" : "password"}
                                                    name="password"
                                                    id="password"
                                                    placeholder="Create A Password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    required
                                                    aria-invalid={!!passwordError}
                                                />
                                                <i 
                                                    className={isPasswordVisible ? "bi bi-eye" : "bi bi-eye-slash"} 
                                                    id="togglePassword" 
                                                    onClick={togglePasswordVisibility}
                                                    style={{ cursor: 'pointer' }}
                                                ></i>
                                            </div>
                                            {passwordError && <div className="error-message" style={{ color: 'red' }}>{passwordError}</div>}
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-agreement form-inner d-flex justify-content-between flex-wrap">
                                                <div className="form-group">
                                                    <input
                                                        type="checkbox"
                                                        id="terms"
                                                        checked={termsAccepted}
                                                        onChange={(e) => setTermsAccepted(e.target.checked)}
                                                        required
                                                    />
                                                    <label htmlFor="terms">I agree to the <a href='#'>Terms & Policy</a></label>
                                                </div>
                                            </div>
                                            {termsError && <div className="error-message" style={{ color: 'red' }}>{termsError}</div>}
                                        </div>
                                    </div>
                                    {error && <div className="error-message" style={{ color: 'red' }}>{error}</div>}
                                    <button className="account-btn" type="submit" disabled={loading}>Create Account</button>
                                </form>
                                {success && <div className="success-message">{success}</div>}
                                <div className="form-policy-area">
                                    <p>By clicking the "signup" button, you create a Ojaak account, and you agree to Ojaak's <a className='terms-policy' href="home2" target="_blank">Terms & Conditions</a> & <a className='terms-policy' href="home3" target="_blank">Privacy Policy.</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showOtpPopup && <OTPPopup email={email} onClose={() => setShowOtpPopup(false)} onSuccess={handleOTPSuccess} />}
            </div>
        </div>
    );
};
export default Indexcontent;