import React from "react";

const OrderTracking = () => {
  return (
    <div className="container" style={{ maxWidth: "100%",padding:"0PX" }}>
      <div className="card border-0" style={{backgroundColor:"#E0FFEB"}}>
        <div className="card-body">
          {/* Order ID and Date */}
          <div style={{backgroundColor:"white",display: "flex",flexDirection: "column", alignItems:"flex-start", padding:"4px"}}>
          <h4 className="card-title tracking-status font-weight: 900">Order ID: 8957981244895122</h4>
          <p className="tracking-status-sub"style={{color:'#555555',margin:'0px'}}>Order date: Nov 20, 2024 Tuesday</p>
          <p className="tracking-status-sub"style={{color:'#32C36C',margin:"0px"}}>
            <i className="bi bi-truck"></i>
              Estimated Delivery: Nov 28, 2024 Monday
          </p>
          </div>

          {/* Timeline */}
          <div className="mt-4">
            <div className="d-flex align-items-start mb-3">
              <div className="">
                <img src="/assets/images/icons/orders.svg" width={60} height={60} alt='order' />
              </div>
              <div className="ms-3">
                <h6 className="tracking-status">Order Confirmed</h6>
                <p className="small tracking-status-sub" style={{textAlign:'left',padding:"0px",margin:"0px"}}>20th November</p>
                <p className="small tracking-status-sub">3:01 PM</p>
              </div>
            </div>

            <div className="d-flex align-items-start mb-3">
            <div className="">
                <img src="/assets/images/icons/shipped.svg" width={60} height={60} alt='shipped' />
              </div>
              <div className="ms-3">
                <h6 className="tracking-status">Shipped</h6>
                <p className="small tracking-status-sub" style={{textAlign:'left',padding:"0px",margin:"0px"}}>Expected on 21st November</p>
                <p className="small tracking-status-sub">_ _ : _ _</p>
              </div>
            </div>

            <div className="d-flex align-items-start mb-3">
            <div className="">
                <img src="/assets/images/icons/outForDelivery.svg" width={60} height={60} alt='outForDelivery' />
              </div>
              <div className="ms-3">
                <h6 className="tracking-status">Out For Delivery</h6>
                <p className="small tracking-status-sub" style={{textAlign:'left',padding:"0px",margin:"0px"}}>Expected on 28th November</p>
                <p className="small tracking-status-sub">_ _ : _ _</p>
              </div>
            </div>

            <div className="d-flex align-items-start mb-3">
            <div className="">
                <img src="/assets/images/icons/delivered.svg" width={60} height={60} alt='delivered' />
              </div>
              <div className="ms-3">
                <h6 className="tracking-status">Delivered</h6>
                <p className="small tracking-status-sub" style={{textAlign:'left',padding:"0px",margin:"0px"}}>28th November</p>
                <p className="small tracking-status-sub">9.00 AM</p>
              </div>
            </div>
          </div>

          {/* Buttons */}
          <button className="btn w-100 mt-3"style={{backgroundColor:'#32C36C',color:'#FFFFFF'}}>
          <img src="/assets/images/icons/vector.svg" width={30} height={30} alt='vector' />
            Track Your Order</button>
         

          <hr style={{border:'2px solid #32C36C'}}/>

          {/* Shipping Address */}
          <div className="mt-4">
            <h6 className="tracking-status mb-1">Shipping Address (Buyer)</h6>
            <p className="tracking-status-sub mb-1"style={{color:'#555555'}}> Alexander Murugavel </p>
              <p className="tracking-status-sub"style={{color:'#555555'}}>
              No.2, 2nd Floor, <br />
              Muthiyalu Naidu Street, <br />
              Little Mount,<br />
              Saidapet <br />
              600015
            </p>
             <button className="btn btn-light w-100 mt-2 border border-secondary-subtle font"> <img src="/assets/images/icons/invoice.svg" width={20} height={20} alt='invoice' />Invoice</button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OrderTracking;