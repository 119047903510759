import React from 'react';
import Header from '../header_footer/header';
import Indexabout from '../index/index_about';
import Indexcontent from '../index/index_content';
import Indexcontent3 from '../index/live_auction';
import Indexcontent4 from '../index/index_content4';
import Indexrecentnews from '../index/index_recent_news';
import Indexsponsor from '../index/index_sponsor';
import Indextestimonial from '../index/index_testimonial';
import Footer from '../header_footer/footer';
import Live_auction from '../index/live_auction';

const isAuthenticated = () => !!localStorage.getItem('access_token');

const Home = () => {
    return (
        <div>
            <Header />
            {!isAuthenticated() && <Indexcontent />}
            {/* <Live_auction /> */}
            <Indexcontent4 />
            {/* <Indexsponsor /> */}
            <Footer />
        </div>
    )
}

export default Home