import React from 'react'

const Blogdetailsinnerbanner = () => {
    return (
        <div>
            <div className="blog-details-section pt-120 pb-120">
                <img alt="" src="assets/images/bg/section-bg.png" className="img-fluid section-bg-top" />
                <img alt="" src="assets/images/bg/section-bg.png" className="img-fluid section-bg-bottom" />
                <div className="container">
                    <div className="row gy-5">
                        <div className="col-lg-8">
                            <div className="blog-details-single">
                                <div className="blog-img">
                                    <img alt="" src="assets/images/blog/blog-details.png" className="img-fluid wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s" />
                                </div>
                                <ul className="blog-meta gap-2">
                                    <li><a href="#"><img alt="" src="assets/images/icons/calendar.svg" />Date: 25 Jan 2022</a></li>
                                    <li><a href="#"><img alt="" src="assets/images/icons/tags.svg" />Auction</a></li>
                                    <li><a href="#"><img alt="" src="assets/images/icons/admin.svg" />Posted by Admin</a></li>
                                </ul>
                                <h3 className="blog-title">A brand for a company is like reputation for a person.</h3>
                                <div className="blog-content">
                                    <p className="para">Gochujang ugh viral, butcher pabst put a bird on it meditation austin craft beer banh. Distillery ramps af, goch ujang hell of VHS kitsch austin. Vegan air plant trust fund, poke sartorial ennui next lev el photo booth coloring book etsy green juice meditation austin craft beer.</p>
                                    <blockquote>
                                        <img alt="" src="assets/images/icons/quote-fill-green.svg" className="quote-icon" />
                                        <p className="para">“If the delivery provider maintains all the standards then it is safe to have get products delivered to you. It is hard to maintain but still safer to get your products ordered If you’ve been following the crypto space”</p>
                                        <h5>-- Leslie Alexander</h5>
                                    </blockquote>
                                    <h4 className="sub-title">How can have anything you ant in life if you ?</h4>
                                    <p className="para">If you’ve been following the crypto space, you’ve likely heard of Non-Fungible Tokens (Biddings), more popularly referred to as ‘Crypto Collectibles.’ The world of Biddings is growing rapidly. It seems there is no slowing down of these assets as they continue to go up in price. This growth comes with the opportunity for people to start new businesses to create and capture value. The market is open for players in every kind of field. Are you a collector.</p>
                                    <div className="blog-video-area">
                                        <div className="row g-4">
                                            <div className="col-md-6">
                                                <img alt="" src="assets/images/blog/blogd1.png" className="img-fluid" />
                                            </div>
                                            <div className="col-md-6">
                                                <div className="video-wrapper position-relative">
                                                    <div className="video-play">
                                                        <a href="https://www.youtube.com/watch?v=u31qwQUeGuM" className="popup-youtube video-icon"><i className="bx bx-play"></i></a>
                                                    </div>
                                                    <img alt="" src="assets/images/blog/blogd2.png" className="img-fluid" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="para">Gochujang ugh viral, butcher pabst put a bird on it meditation austin craft beer banh. Distillery ramps af, goch ujang hell of VHS kitsch austin. Vegan air plant trust fund, poke sartorial ennui next lev el photo booth coloring book etsy green juice meditation austin craft beer.</p>
                                </div>
                                <div className="blog-tag">
                                    <div className="row g-3">
                                        <div className="col-md-6 d-flex justify-content-md-start justify-content-center align-items-center">
                                            <h6>Post Tag:</h6>
                                            <ul className="tag-list">
                                                <li><a href="#">Network Setup</a></li>
                                                <li><a href="#">Cars</a></li>
                                                <li><a href="#">Technology</a></li>
                                            </ul>
                                        </div>
                                        <div className="col-md-6 d-flex justify-content-md-end justify-content-center align-items-center">
                                            <ul className="share-social gap-3">
                                                <li><a href="https://www.facebook.com/"><i className='bx bxl-facebook'></i></a></li>
                                                <li><a href="https://www.twitter.com/"><i className='bx bxl-twitter'></i></a></li>
                                                <li><a href="https://www.instagram.com/"><i className='bx bxl-instagram'></i></a></li>
                                                <li><a href="https://www.pinterest.com/"><i className='bx bxl-pinterest-alt'></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="blog-author gap-4 flex-md-nowrap flex-wrap">
                                    <div className="author-image">
                                        <img alt="" src="assets/images/blog/blog-author.png" className="img-fluid" />
                                    </div>
                                    <div className="author-detials text-md-start text-center">
                                        <h5>-- Leslie Alexander</h5>
                                        <p className="para">It has survived not only five centuries, but also the leap into electronic typesetting unchanged. It was popularised in the sheets containing lorem ipsum is simply free text.</p>
                                    </div>
                                </div>
                                <div className="blog-comment">
                                    <div className="blog-widget-title">
                                        <h4>Comments (03)</h4>
                                        <span></span>
                                    </div>
                                    <ul className="comment-list mb-50">
                                        <li>
                                            <div className="comment-box">
                                                <div className="comment-header d-flex justify-content-between align-items-center">
                                                    <div className="author d-flex flex-wrap">
                                                        <img alt="" src="assets/images/blog/comment1.png" />
                                                        <h5><a href="#">Leslie Waston</a></h5><span className="commnt-date"> April 6, 2022 at 3:54 am</span>
                                                    </div>
                                                    <a href="#" className="commnt-reply"><i className="bi bi-reply"></i></a>
                                                </div>
                                                <div className="comment-body">
                                                    <p className="para">Aenean dolor massa, rhoncus ut tortor in, pretium tempus neque. Vestibulum venenati leo et dic tum finibus. Nulla vulputate dolor sit amet tristique dapibus.Gochujang ugh viral, butcher     pabst put a bird on it meditation austin.</p>
                                                </div>
                                            </div>
                                            <ul className="comment-reply">
                                                <li>
                                                    <div className="comment-box">
                                                        <div className="comment-header d-flex justify-content-between align-items-center">
                                                            <div className="author d-flex flex-wrap">
                                                                <img alt="" src="assets/images/blog/comment2.png" />
                                                                <h5><a href="#">Robert Fox</a></h5><span className="commnt-date"> April 6, 2022 at 3:54 am</span>
                                                            </div>
                                                            <a href="#" className="commnt-reply"><i className="bi bi-reply"></i></a>
                                                        </div>
                                                        <div className="comment-body">
                                                            <p className="para">Aenean dolor massa, rhoncus ut tortor in, pretium tempus neque. Vestibulum venenati leo et dic tum finibus. Nulla vulputate dolor sit amet tristique dapibus.Gochujang ugh viral, butcher     pabst put a bird on it meditation austin.</p>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <div className="comment-box">
                                                <div className="comment-header d-flex justify-content-between align-items-center">
                                                    <div className="author d-flex flex-wrap">
                                                        <img alt="" src="assets/images/blog/comment3.png" />
                                                        <h5><a href="#">William Harvey</a></h5><span className="commnt-date"> April 6, 2022 at 3:54 am</span>
                                                    </div>
                                                    <a href="#" className="commnt-reply"><i className="bi bi-reply"></i></a>
                                                </div>
                                                <div className="comment-body">
                                                    <p className="para">Aenean dolor massa, rhoncus ut tortor in, pretium tempus neque. Vestibulum venenati leo et dic tum finibus. Nulla vulputate dolor sit amet tristique dapibus.Gochujang ugh viral, butcher     pabst put a bird on it meditation austin.</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="comment-form">
                                    <div className="blog-widget-title style2">
                                        <h4>Leave A Comment</h4>
                                        <p className="para">Your email address will not be published.</p>
                                        <span></span>
                                    </div>
                                    <form action="#">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-12 col-md-6">
                                                <div className="form-inner">
                                                    <input type="text" placeholder="Your Name :" />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-12 col-md-6">
                                                <div className="form-inner">
                                                    <input type="email" placeholder="Your Email :" />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-inner">
                                                    <textarea name="message" placeholder="Write Message :" rows={12}></textarea>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <button type="submit" className="eg-btn btn--primary btn--md form--btn">Submit Now</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">

                            <div className="blog-sidebar">
                                <div className="blog-widget-item wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".2s">
                                    <div className="search-area">
                                        <div className="sidebar-widget-title">
                                            <h4>Search From Blog</h4>
                                            <span></span>
                                        </div>
                                        <div className="blog-widget-body">
                                            <form>
                                                <div className="form-inner">
                                                    <input type="text" placeholder="Search Here" />
                                                    <button className="search--btn"><i className='bx bx-search-alt-2'></i></button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="blog-widget-item wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".4s">
                                    <div className="blog-category">
                                        <div className="sidebar-widget-title">
                                            <h4>Recent Post</h4>
                                            <span></span>
                                        </div>
                                        <div className="blog-widget-body">
                                            <ul className="recent-post">
                                                <li className="single-post">
                                                    <div className="post-img">
                                                        <a href="blog-details.html"><img alt="" src="assets/images/blog/recent-feed1.png"
                                                        /></a>
                                                    </div>
                                                    <div className="post-content">
                                                        <span>January 31, 2022</span>
                                                        <h6><a href="blog-details.html">Grant Distributions Conti nu to Incr Ease.</a>
                                                        </h6>
                                                    </div>
                                                </li>
                                                <li className="single-post">
                                                    <div className="post-img">
                                                        <a href="blog-details.html"><img alt="" src="assets/images/blog/recent-feed2.png"
                                                        /></a>
                                                    </div>
                                                    <div className="post-content">
                                                        <span>February 21, 2022</span>
                                                        <h6><a href="blog-details.html">Seminar for Children to Learn About.</a></h6>
                                                    </div>
                                                </li>
                                                <li className="single-post">
                                                    <div className="post-img">
                                                        <a href="blog-details.html"><img alt="" src="assets/images/blog/recent-feed3.png"
                                                        /></a>
                                                    </div>
                                                    <div className="post-content">
                                                        <span>March 22, 2022</span>
                                                        <h6><a href="blog-details.html">Education and teacher for all African
                                                            Children.</a></h6>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="blog-widget-item wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".4s">
                                    <div className="top-blog">
                                        <div className="sidebar-widget-title">
                                            <h4>Post Categories</h4>
                                            <span></span>
                                        </div>
                                        <div className="blog-widget-body">
                                            <ul className="category-list">
                                                <li><a href="#"><span>New Technology</span><span>01</span></a></li>
                                                <li><a href="#"><span>Network Setup</span><span>12</span></a></li>
                                                <li><a href="#"><span>Audi Car Bidding </span><span>33</span></a></li>
                                                <li><a href="#"><span>Entertainment</span><span>54</span></a></li>
                                                <li><a href="#"><span>New Technology</span><span>24</span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="blog-widget-item wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".8s">
                                    <div className="tag-area">
                                        <div className="sidebar-widget-title">
                                            <h4>Follow Social</h4>
                                            <span></span>
                                        </div>
                                        <div className="blog-widget-body">
                                            <ul className="sidebar-social-list gap-4">
                                                <li><a href="https://www.facebook.com/"><i className='bx bxl-facebook'></i></a></li>
                                                <li><a href="https://www.twitter.com/"><i className='bx bxl-twitter'></i></a></li>
                                                <li><a href="https://www.instagram.com/"><i className='bx bxl-instagram'></i></a></li>
                                                <li><a href="https://www.pinterest.com/"><i className='bx bxl-pinterest-alt'></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="sidebar-banner wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="1s">
                                    <div className="banner-content">
                                        <span>CARS</span>
                                        <h3>Toyota AIGID A Clasis Cars Sale</h3>
                                        <a href="auction-details.html" className="eg-btn btn--primary card--btn">Details</a>
                                    </div>
                                </div>x
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Blogdetailsinnerbanner