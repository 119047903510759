import React from 'react';
import Header from '../header_footer/header';
import Aboutus from '../live_auction/about_us';
import Liveauctionsection from '../live_auction/live_auction_section';
import Footer from '../header_footer/footer';

const Liveauction = () => {
  return (
    <div>
        <Header />
        <Liveauctionsection />
        <Footer />
    </div>
  )
}

export default Liveauction