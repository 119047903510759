import React from 'react';
import Header from '../header_footer/header';
// import Aboutuscounterlogin from '../login/about_us_counter';
import Innerbannerlogin from '../login/inner_banner_login';
import Footer from '../header_footer/footer';

const Login = () => {
  return (
    <div>
        <Header />
        <Innerbannerlogin />
        {/* <Aboutuscounterlogin /> */}
        <Footer />
    </div>
  )
}

export default Login