import React from 'react'

const Abouttestimonial = () => {
    return (
        <div>
            <div className="testimonial-section pt-80 pb-80">
                <img src="assets/images/bg/client-right.png" className="client-right-vector" alt="" />
                <img src="assets/images/bg/client-left.png" className="client-left-vector" alt="" />
                <img src="assets/images/bg/clent-circle1.png" className="client-circle1" alt="" />
                <img src="assets/images/bg/clent-circle2.png" className="client-circle2" alt="" />
                <img src="assets/images/bg/clent-circle3.png" className="client-circle3" alt="" />
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6">
                            <div className="section-title1">
                                <h2>What Client Say</h2>
                                <p className="mb-0">Explore on the world's best & largest Bidding marketplace with our beautiful Bidding
                                    products. We want to be a part of your smile, success and future growth.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center position-relative">
                        <div className="swiper testimonial-slider2">
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <div className="testimonial-single style2 hover-border1 wow fadeInDown" data-wow-duration="1.5s" data-wow-delay="0.3s">
                                        <img src="assets/images/icons/quote-green.svg" className="quote-icon" alt="quote-icon" />
                                        <div className="testi-img">
                                            <img src="assets/images/bg/testi1.png" alt="" />
                                        </div>
                                        <div className="testi-content">
                                            <div className="testi-designation">
                                                <h5>Johan Martin R</h5>
                                                <p>CEO Founder</p>
                                            </div>
                                            <p className="para">The Pacific Grove Chamber of Commerce would like to thank eLab
                                                Communications and Mr. Will Elkadi for all the efforts  that
                                                assisted me nicely manners. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="testimonial-single style2 hover-border1 wow fadeInDown" data-wow-duration="1.5s" data-wow-delay="0.6s">
                                        <img src="assets/images/icons/quote-green.svg" className="quote-icon" alt="quote-icon" />
                                        <div className="testi-img">
                                            <img src="assets/images/bg/testi2.png" alt="" />
                                        </div>
                                        <div className="testi-content">
                                            <div className="testi-designation">
                                                <h5>Jamie Anderson</h5>
                                                <p>Founder</p>
                                            </div>
                                            <p className="para">Maecenas vitae porttitor neque, ac porttitor nunc. Duis venenatis lacinia libero. Nam nec augue ut nunc vulputate tincidunt at suscipit nunc. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="testimonial-single style2 hover-border1 wow fadeInDown" data-wow-duration="1.5s" data-wow-delay="0.9s">
                                        <img src="assets/images/icons/quote-green.svg" className="quote-icon" alt="quote-icon" />
                                        <div className="testi-img">
                                            <img src="assets/images/bg/testi3.png" alt="" />
                                        </div>
                                        <div className="testi-content">
                                            <div className="testi-designation">
                                                <h5>John peter</h5>
                                                <p>CEO Founder</p>
                                            </div>
                                            <p className="para">Nullam cursus tempor ex. Nullam nec dui id metus consequat congue ac at est. Pellentesque blandit neque at elit tristique tincidunt.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slider-arrows testimonial2-arrow d-flex justify-content-between gap-3">
                            <div className="testi-prev2 swiper-prev-arrow" tabIndex={0} role="button"
                                aria-label="Previous slide"><i className="bi bi-arrow-left"></i></div>
                            <div className="testi-next2 swiper-next-arrow" tabIndex={0} role="button"
                                aria-label="Next slide">
                                <i className="bi bi-arrow-right"></i></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Abouttestimonial