import React, { createContext, useState, useContext } from 'react';

const WalletContext = createContext();
export const useWallet = () => useContext(WalletContext);

export const WalletProvider = ({ children }) => {
    const [walletAmount, setWalletAmount] = useState(0);

    return (
        <WalletContext.Provider value={{ walletAmount, setWalletAmount }}>
            {children}
        </WalletContext.Provider>
    );
};