import VerifyOTP from "../email_validation/verifyOtp";
import Footer from "../header_footer/footer";
import Header from "../header_footer/header";

const Verifyemailotp = () => {
    return (
        <div>
            <Header />
            <VerifyOTP />
            <Footer />
        </div>
    )
}
export default Verifyemailotp;