import React from 'react'
import Header from '../header_footer/header';
import Index3about from '../index3/index3_about';
import Index3categorysection from '../index3/index3_category_section';
import Index3hero from '../index3/index3_hero';
import Index3liveauction from '../index3/index3_live_auction';
import Index3recentnews from '../index3/index3_recent_news';
import Index3sponsor from '../index3/index3_sponsor';
import Index3testimonial from '../index3/index3_testimonial';
import Index3upcomingsection from '../index3/index3_upcoming_section';
import Footer from '../header_footer/footer';

const Home3 = () => {
    return ( 
            <div className='container'>
            <h1 className='mb-50' style={{marginTop: '50px'}}>Privacy Policy</h1>

            <h2>Introduction</h2>
            <p>
                This Privacy Policy describes how OJAAK INFO PRIVATE LIMITED and its affiliates (collectively "OJAAK INFO PRIVATE LIMITED, we, our, us") collect, use, share, protect, or otherwise process your information/personal data through our website www.ojaak.com (hereinafter referred to as Platform).
            </p>
            <ul>
                <li>
                    You may browse certain sections of the Platform without registering with us.
                </li>
                <li>
                    We do not offer any product/service under this Platform outside India, and your personal data will primarily be stored and processed in India.
                </li>
                <li>
                    By visiting this Platform, providing your information, or availing of any product/service offered on the Platform, you expressly agree to the terms of this Privacy Policy.
                </li>
                <li>
                    If you do not agree, please do not use or access our Platform.
                </li>
            </ul>

            <h2>Collection</h2>
            <ul>
                <li>
                    We collect your personal data when you use our Platform, services, or otherwise interact with us.
                </li>
                <li>
                    This includes information provided during sign-up, such as name, date of birth, address, telephone number, email ID, and more.
                </li>
                <li>
                    Sensitive personal data like payment information or biometric data may be collected with your consent.
                </li>
                <li>
                    You always have the option not to provide information by choosing not to use specific services or features on the Platform.
                </li>
                <li>
                    We may track your behavior, preferences, and transaction details on our Platform and those of third-party business partners.
                </li>
            </ul>

            <h2>Usage</h2>
            <ul>
                <li>
                    Personal data is used to provide services you request, enhance customer experience, resolve disputes, and prevent fraud.
                </li>
                <li>
                    It may also be used for marketing purposes, with an opt-out option available.
                </li>
            </ul>

            <h2>Sharing</h2>
            <ul>
                <li>
                    We may share personal data internally within our group entities, affiliates, or with third parties like sellers, logistics partners, and government agencies, if required by law.
                </li>
                <li>
                    Disclosure to law enforcement agencies will occur as per legal obligations or in good faith to protect rights or safety.
                </li>
            </ul>

            <h2>Security Precautions</h2>
            <ul>
                <li>
                    We adopt reasonable security practices to protect your data but acknowledge the inherent risks of data transmission over the internet.
                </li>
                <li>
                    Users are responsible for protecting their account login details.
                </li>
            </ul>

            <h2>Data Deletion and Retention</h2>
            <ul>
                <li>
                    You can delete your account through the Platform or by contacting us.
                </li>
                <li>
                    Retention of data will be for as long as necessary to fulfill the purposes it was collected for, or as required by law.
                </li>
            </ul>

            <h2>Your Rights</h2>
            <ul>
                <li>
                    You may access, rectify, and update your personal data directly through the Platform.
                </li>
            </ul>

            <h2>Consent</h2>
            <ul>
                <li>
                    By providing your information, you consent to the collection, use, and processing of your data in line with this Privacy Policy.
                </li>
                <li>
                    Withdrawal of consent can be requested, but it may impact your access to services.
                </li>
            </ul>

            <h2>Changes to this Privacy Policy</h2>
            <ul>
                <li>
                    Please check the Privacy Policy periodically for updates.
                </li>
                <li>
                    We will notify you of significant changes as required by applicable laws.
                </li>
            </ul>

            <h2>Grievance Officer</h2>
            <p>
                Name of the Officer: RAMESHKUMAR
                <br />
                Designation: [Provide designation]
                <br />
                Address: 4/1, Nethaji Thenvadal 1st Street, Aruppukottai, Virudhunagar, Tamil Nadu, 626101
            </p>
        </div>
    )
}

export default Home3