import React, { useState, useEffect } from "react";
import api from "../../api";
import { useLocation, useNavigate } from "react-router-dom";

const VerifyOTP = () => {
    const [otp, setOtp] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [resendSuccess, setResendSuccess] = useState('');
    const [resendTimer, setResendTimer] = useState(30); // Set the countdown to 30 seconds
    const [canResend, setCanResend] = useState(false); // Initially, the button is disabled
    const navigate = useNavigate();
    const location = useLocation();
    const email = location.state?.email || '';

    useEffect(() => {
        let timer;
        if (resendTimer > 0) {
            timer = setTimeout(() => {
                setResendTimer(resendTimer - 1);
            }, 1000);
        } else {
            setCanResend(true); // Enable the button after the countdown
        }

        return () => clearTimeout(timer);
    }, [resendTimer]);

    const handleVerifyOTP = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');
        setResendSuccess('');

        if (!otp) {
            setError('OTP is required.');
            return;
        }

        try {
            const response = await api.post('verify_otp/', { email, otp });
            setSuccess(response.data.detail);
            navigate('/reset-password', { state: { email } });
        } catch (error) {
            if (error.response && error.response.data) {
                setError(error.response.data.detail);
            } else {
                setError('Failed to verify OTP, please try again.');
            }
        }
    };

    const handleResendOTP = async () => {
        setError('');
        setSuccess('');
        setResendSuccess('');

        try {
            const response = await api.post('resend_otp/', { email });
            setResendSuccess('A new OTP has been sent to your email.');
            setCanResend(false); // Disable the button again
            setResendTimer(30); // Restart the countdown
        } catch (error) {
            if (error.response && error.response.data) {
                setError(error.response.data.detail);
            } else {
                setError('Failed to resend OTP, please try again.');
            }
        }
    };

    return (
        <div className="verify-otp-section pt-120 pb-120">
            <div className="container">
                <div className="row d-flex justify-content-center g-4">
                    <div className="col-xl-6 col-lg-8 col-md-10">
                        <div className="form-wrapper wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".2s">
                            <div className="form-title">
                                <h3>Verify OTP</h3>
                                <p>Please enter the OTP sent to your email.</p>
                            </div>
                            <form className="w-100" onSubmit={handleVerifyOTP} noValidate>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-inner">
                                            <input
                                                type="text"
                                                placeholder="Enter OTP"
                                                value={otp}
                                                onChange={(e) => setOtp(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                {error && <div className="error-message" style={{ color: 'red' }}>{error}</div>}
                                {success && <div className="success-message">{success}</div>}
                                {resendSuccess && <div className="success-message">{resendSuccess}</div>}
                                <button className="account-btn mt-2">Verify OTP</button>
                            </form>
                            <div className="resend-otp mt-3">
                                {!canResend ? (
                                    <p>Resend OTP available in {resendTimer} seconds</p>
                                ) : (
                                    <button onClick={handleResendOTP} className="resend-otp-btn1">Resend OTP</button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VerifyOTP;
