import React, { useState } from "react";
import { validateEmail } from "./validation";
import api from "../../api";
import { useNavigate } from "react-router-dom";


const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();

    const handleSendOTP = async (e) => {
        e.preventDefault();
        setEmailError('');
        setError('');
        setSuccess('');

        // Form validation: Check if email is provided and valid
        if (!email) {
            setEmailError('This field is required.');
            return;
        } else if (!validateEmail(email)) {
            setEmailError('Email must be a valid email address.');
            return;
        }

        try {
            // Make API request to send OTP
            const response = await api.post('resend_otp/', { email });
            setSuccess(response.data.detail);  // Show success message

            // Navigate to OTP verification page, passing email state
            navigate('/verify_otp', { state: { email } });
        } catch (error) {
            // Capture and display the "User does not exist" error from the API
            if (error.response && error.response.data) {
                setError(error.response.data.detail);  // Set error message from API response
            } else {
                setError('Failed to send OTP, please try again.');
            }
        }
    };

    return (
        <div className="forgot-password-section pt-120 pb-120">
            <div className="container">
                <div className="row d-flex justify-content-center g-4">
                    <div className="col-xl-6 col-lg-8 col-md-10">
                        <div className="form-wrapper wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".2s">
                            <div className="form-title">
                                <h3>Forgot Password</h3>
                                <p>Please enter your email to receive an OTP for password reset.</p>
                            </div>
                            <form className="w-100" onSubmit={handleSendOTP} noValidate>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-inner">
                                            <input
                                                type="email"
                                                placeholder="Enter Your Email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                            {emailError && <div className="error-message" style={{ color: 'red' }}>{emailError}</div>}
                                        </div>
                                    </div>
                                </div>
                                {error && <div className="error-message" style={{ color: 'red' }}>{error}</div>}
                                {success && <div className="success-message" style={{ color: 'green' }}>{success}</div>}
                                <button className="account-btn" type="submit">Send OTP</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
