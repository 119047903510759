import React, { useEffect, useState } from 'react'
import api from '../../api';
import moment from 'moment-timezone';
import Timer from '../registration_timer_components/Timer';
import { Link, useParams } from 'react-router-dom';
import { useWallet } from '../../WalletContext';
import { usePaymentMethod } from '../../paymentMethodContext';
import Modal from 'react-modal';

const Upcomingauctionsection = () => {
    const { id } = useParams();
    const [details, setDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [registrationStatus, setRegistrationStatus] = useState({});
    const [isRegistered, setIsRegistered] = useState(false);
    const [isBidModalOpen, setIsBidModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { walletAmount, setWalletAmount } = useWallet();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { paymentMethod } = usePaymentMethod();
    const [selectedAuction, setSelectedAuction] = useState(null);

    const isAuthenticated = () => !!localStorage.getItem('access_token');

    // Fetch auction details and registration status when component mounts
    useEffect(() => {
        Modal.setAppElement('#root');

        const fetchDetails = async () => {
            try {
                const response = await api.get('auctions/?phase=upcoming');
                setDetails(response.data);
                setLoading(false);
                
                // Check if user is authenticated, then fetch registration statuses
                if (isAuthenticated()) {
                    fetchRegistrationStatuses(response.data);
                }
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        // Fetch registration statuses from the backend
        const fetchRegistrationStatuses = async (auctions) => {
            try {
                const statuses = await Promise.all(
                    auctions.map(async (auction) => {
                        // First, check if the status is stored in localStorage
                        const savedStatus = localStorage.getItem(`isRegistered_${auction.auction_id}`);
                        if (savedStatus !== null) {
                            return { auctionId: auction.auction_id, isRegistered: JSON.parse(savedStatus) };
                        }

                        // If not in localStorage, fetch it from the backend
                        const response = await api.get(`registration-status/${auction.auction_id}/`, {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                            },
                        });
                        const isRegistered = response.data.is_registered;
                        
                        // Store the registration status in localStorage
                        localStorage.setItem(`isRegistered_${auction.auction_id}`, JSON.stringify(isRegistered));
                        
                        return { auctionId: auction.auction_id, isRegistered };
                    })
                );

                const statusMap = {};
                statuses.forEach(status => {
                    statusMap[status.auctionId] = status.isRegistered;
                });

                setRegistrationStatus(statusMap);
            } catch (error) {
                console.error("Failed to fetch registration statuses:", error);
            }
        };

        fetchDetails();
    }, [id]);

    const handleRegisterNow = async () => {
        const token = localStorage.getItem('access_token');
        setErrorMessage(null);
        if (!selectedAuction) {
            setErrorMessage('No auction selected.');
            return;
        }
        if (paymentMethod === 'wallet') {
            handleWalletRegister();
        } else if (paymentMethod === 'razorpay') {
            try {
                const auctionDetails = selectedAuction;
                if (!auctionDetails || auctionDetails.registration_price <= 0) {
                    setErrorMessage('Invalid registration price.');
                    return;
                }
                const response = await api.post(`register-bid/razorpay/${auctionDetails.auction_id}/`,
                    { registration_price: auctionDetails.registration_price },
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'X-CSRFToken': getCookie('csrftoken')
                        }
                    });
                // Step 2: Process successful order creation
                const data = response.data;
                const options = {
                    key: data.razorpay_key_id,
                    amount: data.registration_price * 100, 
                    currency: 'INR',
                    name: 'Auction Registration',
                    description: 'Register for Auction',
                    order_id: data.order_id,
                    prefill: {
                        name: data.user_name || '',
                        email: data.user_email || '',
                        contact: data.user_contact || '',
                    },
                    theme: {
                        color: '#3399cc'
                    },
                    handler: async function (paymentResult) {
                        await verifyRazorpayPayment(paymentResult);
                    }
                };
                const rzp = new window.Razorpay(options);
                rzp.open();
            } catch (error) {
                handleErrorResponse(error);
            }
        }
    };

    const verifyRazorpayPayment = async (paymentResult) => {
        try {
            const auctionDetails = selectedAuction;
            const response = await api.post(`register-bid/razorpay/verify/${auctionDetails.auction_id}/`, {
                razorpay_order_id: paymentResult.razorpay_order_id,
                razorpay_payment_id: paymentResult.razorpay_payment_id,
                razorpay_signature: paymentResult.razorpay_signature,
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                }
            });

            if (response.data.status === 'payment verified') {
                setIsRegistered(true);
                localStorage.setItem(`isRegistered_${selectedAuction.auction_id}`, JSON.stringify(true));
                setIsModalOpen(false);
                setErrorMessage('Registration successful!');
                setTimeout(() => setErrorMessage(null), 3000);
                setRegistrationStatus(prev => ({
                    ...prev,
                    [selectedAuction.auction_id]: true
                }));
            } else {
                setErrorMessage('Payment verification failed.');
                setTimeout(() => setErrorMessage(null), 3000);
            }
        } catch (error) {
            console.error('Error during payment verification:', error);
            setErrorMessage('Payment verification failed.');
            setTimeout(() => setErrorMessage(null), 3000);
        }
    };

    const handleWalletRegister = async () => {
        try {
            const auctionDetails = selectedAuction;
            const response = await api.post(`${auctionDetails.auction_id}/register/`, {
                registration_price: auctionDetails.registration_price
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                }
            });
            if (response.status === 200) {
                setWalletAmount(response.data.wallet_amount || walletAmount);
                setIsRegistered(true);
                localStorage.setItem(`isRegistered_${auctionDetails.auction_id}`, JSON.stringify(true));
                setIsModalOpen(false);
                setRegistrationStatus(prev => ({
                    ...prev,
                    [auctionDetails.auction_id]: true
                }));
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                setErrorMessage('Insufficient amount in wallet to register for the bid.');
                setTimeout(() => setErrorMessage(null), 3000);
            } else {
                console.error('Registration failed:', error);
                setError(error);
            }
        }
    };

    const handleRegisterClick = (auction) => {
        setSelectedAuction(auction);
        setIsModalOpen(true);
    };

    const handleErrorResponse = (error) => {
        if (error.response) {
            const status = error.response.status;
            const backendError = error.response.data.error;
            if (status === 400) {
                setErrorMessage(backendError || 'Bad request. Please check your input.');
            } else if (status === 403) {
                setErrorMessage(backendError || 'You are not authorized to register.');
            } else if (status === 500) {
                setErrorMessage('An internal server error occurred. Please try again later.');
            } else {
                setErrorMessage(backendError || 'An unexpected error occurred.');
            }
        } else {
            setErrorMessage('Network error. Please check your connection.');
        }
    };
 
    const handleModalClose = () => {
        setIsModalOpen(false);
    };
 
    function getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }
 
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '600px',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        },
    };
 
    const modalContentStyles = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
    };
 
    const modalHeaderStyles = {
        fontSize: '32px',
        fontWeight: 'bold',
    };
 
    const modalSubHeaderStyles = {
        fontSize: '16px',
        fontWeight: '400',
    }
 
    const modalProductInfoStyles = {
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
        width: '100%',
        marginTop: '20px',
    };
 
    const modalImageStyles = {
        width: '150px',
        height: '150px',
        objectFit: 'cover',
    };
 
    const modalDetailsStyles = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '10px',
    };
    const modalButtonStyles = {
        backgroundColor: '#28a745',
        color: '#fff',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: '700',
    };
    const closeButtonStyles = {
        position: 'absolute',
        right: '10px',
        backgroundColor: 'transparent',
        border: 'none',
        fontSize: '24px',
        cursor: 'pointer',
    };
    
    return (
            <div>
                <div className="live-auction pt-50 pb-120">
                    <img alt="" src="assets/images/bg/section-bg.png" className="img-fluid section-bg" />
                    <div className="container position-relative">
                        <img alt="" src="assets/images/bg/dotted1.png" className="dotted1" />
                        <img alt="" src="assets/images/bg/dotted1.png" className="dotted2" />
                        <div className="row d-flex justify-content-center">
                            <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6">
                                <div className="section-title1">
                                    <h2>Upcoming Auction</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row gy-4 mb-60 d-flex justify-content-center">
                        {details.slice().map((auction, index) => {
                                const registrationOngoing = moment().tz('Asia/Kolkata').isBetween(moment.tz(auction.registration_starting_time, 'Asia/Kolkata'), moment.tz(auction.registration_end_time, 'Asia/Kolkata'));
                                if (!registrationOngoing) return null; // Only display if registration is ongoing
                                const isRegistered = registrationStatus[auction.auction_id];
                                return (
                                    <div key={auction.auction_id} className="col-lg-3 col-md-6 col-sm-12">
                                        <div data-wow-duration="1.5s" data-wow-delay={`${0.2 * (index + 1)}s`} className="eg-card auction-card1 wow animate fadeInDown">
                                            <div className="auction-img">
                                                {auction.image && auction.image.length > 0 ? (
                                                    <img alt="" src={auction.image[0].image_url} />
                                                ) : (
                                                    <img alt="" src="assets/images/bg/live-auc1.png" />
                                                )}
                                                <div className='share-area'>
                                                    <ul className="social-icons d-flex">
                                                        <li><a href={`https://www.facebook.com/sharer/sharer.php?u=http://alpha.ojaak.com/auction-details/${auction.auction_id}`}><i className="bx bxl-facebook"></i></a></li>
                                                        <li><a href={`https://twitter.com/intent/tweet?url=http://alpha.ojaak.com/auction-details/${auction.auction_id}`}><i className="bx bxl-twitter"></i></a></li>
                                                        <li><a href={`https://pinterest.com/pin/create/button/?url=http://alpha.ojaak.com/auction-details/${auction.auction_id}`}><i className="bx bxl-pinterest"></i></a></li>
                                                        <li><a href={`https://www.instagram.com/?url=http://alpha.ojaak.com/auction-details/${auction.auction_id}`}><i className="bx bxl-instagram"></i></a></li>
                                                    </ul>
                                                    <div>
                                                        <div className="share-btn"><i className='bx bxs-share-alt'></i></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="auction-content mt-3">
                                                <h4><Link to={`/auction-details/${auction.auction_id}`}>{auction.name}</Link></h4>
                                                <div className='d-flex justify-content-between'>
                                                    <div className='graycolor justify-self-start d-flex'>MRP ₹{auction.mrp}</div>
                                                    <div className=''>Auction ID :OJK- {auction.auction_id}</div>
                                                </div>
                                                <div className='row mt-3 d-flex align-items-center liveauction'>
                                                    <div className=' col-12 flex-column align-items-center'>
                                                        <Timer
                                                            registrationStartTime={auction.registration_starting_time}
                                                            registrationEndTime={auction.registration_end_time}
                                                            pageType="upcoming"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="auction-card-bttm row">
                                                    {isAuthenticated() && (
                                                        <div className="col-10">
                                                            {/* Show Registered button if the user has already registered for this auction */}
                                                            {registrationStatus[auction.auction_id] ? (
                                                                <button className="eg-btn w-100 btn--sm btn--success" disabled>
                                                                    Registered
                                                                </button>
                                                            ) : (
                                                                // Show Register button if the user has not registered yet
                                                                <button
                                                                    type="button"
                                                                    className="eg-btn btn--primary btn--sm"
                                                                    onClick={() => handleRegisterClick(auction)}  // Trigger the modal with auction details
                                                                >
                                                                    Register
                                                                </button>
                                                            )}
                                                        </div>
                                                    )}
                                                    {/* If the user is not authenticated, show a link to the login page */}
                                                    {!isAuthenticated() && (
                                                        <div className="col-10 ">
                                                            <Link to={`/login`} className="eg-btn w-100 pbbtn btn--sm btn--primary">
                                                                Register
                                                            </Link>
                                                        </div>
                                                    )}
                                                    <div className="register-area col-2">
                                                        <div className="tooltip-container">
                                                            <img className='registration-status' src='assets/images/bg/crowd_1.png' alt="Registration status" />
                                                            <span className="tooltip-text">Enough participants reached</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>


                <Modal
                isOpen={isModalOpen}
                onRequestClose={handleModalClose}
                contentLabel="Register Bid"
                style={customStyles}
            >
                <div style={modalContentStyles}>
                    <button onClick={handleModalClose} style={closeButtonStyles}>&times;</button>
                    <div style={modalHeaderStyles}>Register Bid</div>
                    <div style={modalSubHeaderStyles}>Register Bid to claim your Product</div>
                    <div style={modalProductInfoStyles}>
                        {selectedAuction?.image?.[0] && (
                            <img
                                src={selectedAuction.image[0].image_url}
                                alt="Product"
                                style={modalImageStyles}
                            />
                        )}
                        <div style={modalDetailsStyles}>
                            <h3 style={{ fontSize: '24px', fontWeight: '700', marginBottom: '0' }}>{selectedAuction?.name}</h3>
                            <p style={{ color: '#F2B200', margin: '0' }}>Time Left:</p>
                            <Timer
                                registrationStartTime={selectedAuction?.registration_starting_time}
                                registrationEndTime={selectedAuction?.registration_end_time}
                                biddingStartTime={selectedAuction?.bidding_start_time}
                                biddingEndTime={selectedAuction?.bidding_end_time}
                            />
                            <p style={{ fontWeight: '700', fontSize: '20px' }}>Registration Amount:
                                <span style={{ fontSize: '16px', background: '#DADADA', padding: '6px', borderRadius: '8px' }}>
                                    ₹{selectedAuction?.registration_price}
                                </span>
                            </p>
                            {errorMessage && (
                                <p className='text-danger'>{errorMessage}</p>
                            )}
                        </div>
                    </div>
                    <div>
                        <button onClick={handleRegisterNow} style={modalButtonStyles}>Register Now</button>
                    </div>
                </div>
            </Modal>
            </div>
        );
    };
export default Upcomingauctionsection;