import React from 'react';
import Header from '../header_footer/header';
import Footer from '../header_footer/footer';
import Closedauctionsection from '../closed_auction/closed_auction_section';

const Closedauction = () => {
  return (
    <div>
        <Header/>
       <Closedauctionsection />
        <Footer />
    </div>
  )
}

export default Closedauction