import React from 'react';
import Header from '../header_footer/header';
import Footer from '../header_footer/footer';
import Auctiondetailinnerbanner from '../auction_details/auction_detail_inner_banner';
import Auctiondetailaboutus from '../auction_details/auction_detail_about_us';

const Auctiondetail = () => {
    return (
        <div>
            <Header />
            <Auctiondetailinnerbanner />
            {/* <Auctiondetailaboutus /> */}
            <Footer />
        </div>
    )
}
export default Auctiondetail