// paymentMethodContext.js
import React, { createContext, useContext, useState } from 'react';

const PaymentMethodContext = createContext();

export const usePaymentMethod = () => {
    return useContext(PaymentMethodContext);
};

export const PaymentMethodProvider = ({ children }) => {
    const [paymentMethod, setPaymentMethod] = useState(() => {
        return localStorage.getItem('paymentMethod') || 'wallet';
    });

    // Function to update the payment method and store it in localStorage
    const selectPaymentMethod = (method) => {
        setPaymentMethod(method);
        localStorage.setItem('paymentMethod', method); // Save to localStorage
    };

    return (
        <PaymentMethodContext.Provider value={{ paymentMethod, selectPaymentMethod }}>
            {children}
        </PaymentMethodContext.Provider>
    );
};
