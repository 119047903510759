import React, { useEffect, useState } from 'react';
import api from '../../api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const OTPPopup = ({ email, onClose, onSuccess }) => {
    const [otp, setOtp] = useState('');
    const [otpError, setOtpError] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    // Reset state when the popup is closed
    useEffect(() => {
        if (!email) {
            setOtp('');
            setOtpError('');
            setError('');
            setSuccess('');
        }
    }, [email]);

    const handleOTPSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');
        setOtpError('');
        if (!otp) {
            setOtpError('This field is required.');
            return;
        }
        try {
            const response = await api.post('verify_otp/', { email, otp });
            setSuccess('OTP verified successfully!');
            toast.success('Account verified!', { autoClose: 3000, closeButton: false });
            setTimeout(() => {
                onSuccess(); 
            }, 3000); 
        } catch (error) {
            setError('Invalid OTP, please try again.');
        }
    };

    const handleResendOTP = async () => {
        setError('');
        setSuccess('');
        try {
            await api.post('resend_otp/', { email });
            setSuccess('A new OTP has been sent to your email.');
            toast.info('A new OTP has been sent to your email.', { autoClose: 3000, closeButton: false });
        } catch (error) {
            setError('Failed to resend OTP, please try again.');
        }
    };

    return (
        <div className="otp-popup">
            <ToastContainer />
            <div className="otp-popup-content">
                <h3>Enter OTP</h3>
                <form onSubmit={handleOTPSubmit} noValidate>
                    <div className="form-inner">
                        <input
                            type="text"
                            placeholder="Enter OTP"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            required
                        />
                        {otpError && <div className="error-message">{otpError}</div>}
                    </div>
                    {error && <div className="error-message">{error}</div>}
                    {success && <div className="success-message">{success}</div>}
                    <button className="account-btn">Continue</button>
                </form>
                <button className="resend-otp-btn" onClick={handleResendOTP}>Resend OTP</button>
                <button className="close-btn" onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default OTPPopup;
