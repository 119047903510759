import React, { createContext, useContext, useState } from 'react';

const BidContext = createContext();

export const BidProvider = ({ children }) => {
    const [bidCounts, setBidCounts] = useState({}); // Store bid counts for auctions

    const updateBidCount = (auctionId, newCount) => {
        setBidCounts((prev) => ({
            ...prev,
            [auctionId]: newCount,
        }));
    };

    return (
        <BidContext.Provider value={{ bidCounts, updateBidCount }}>
            {children}
        </BidContext.Provider>
    );
};

export const useBidContext = () => {
    return useContext(BidContext);
};
