import React from 'react'
import Header from '../header_footer/header';
import Blogdetailabout from '../blog_details/blog_detail_about';
import Blogdetailsinnerbanner from '../blog_details/blog_details_inner_banner';
import Footer from '../header_footer/footer';
const Blogdetails = () => {
  return (
    <div>
        <Header />
        <Blogdetailsinnerbanner />
        {/* <Blogdetailabout /> */}
        <Footer />
    </div>
  )
}

export default Blogdetails