import ResetPassword from "../email_validation/resetPassword";
import Footer from "../header_footer/footer";
import Header from "../header_footer/header";

const Resetpassword = () => {
    return (
        <div>
            <Header />
            <ResetPassword />
            <Footer />
        </div>
    )
}
export default Resetpassword;