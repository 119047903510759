import React from 'react';
import Header from '../header_footer/header';
import Contactaboutus from '../contact/contact_about_us';
import Contactsectioninnerbanner from '../contact/contact_section_inner_banner';
import Footer from '../header_footer/footer';

const Contact = () => {
  return (
    <div>
        <Header />
        <Contactsectioninnerbanner />
        {/* <Contactaboutus /> */}
        <Footer />
    </div>
  )
}

export default Contact