import React from 'react';
import Upcomingauctionsection from '../upcoming_auction/upcoming_auction_section';
import Header from '../header_footer/header';
import Footer from '../header_footer/footer';

const Upcomingauction = () => {
  return (
    <div>
        <Header/>
        <Upcomingauctionsection/>
        <Footer />
    </div>
  )
}

export default Upcomingauction