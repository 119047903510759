import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import api from "../../api";
import { useWallet } from "../../WalletContext";

const Header = () => {
    const navigate = useNavigate(); 
    const isAuthenticated = () => !!localStorage.getItem("access_token");
    const [Show, IsShow] = useState(false);
    const location = useLocation();
    const { walletAmount, setWalletAmount } = useWallet();

    useEffect(() => {
        if (isAuthenticated()) {
            fetchWalletAmount();
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('access_token');
        // localStorage.removeItem('user_role');
        localStorage.removeItem('refresh_token');
        navigate('/');
        // window.location.reload();
    };

    const fetchWalletAmount = async () => {
        try {
            const response = await api.get("wallet-amount/", {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            });
            setWalletAmount(response.data.wallet_amount);
        } catch (error) {
            console.error("Error fetching wallet amount:", error);
        }
    };

    const handleShow = () => {
        IsShow(!Show);
    };

    return (
        <div>
            <header className="header-area style-1">
                <div className="container-fluid">
                    <div className="d-flex justify-content-between align-items-center py-2">
                        <div className="header-logo">
                            <Link to="/">
                                <img alt="" src="/assets/images/bg/ojaak_logo.svg" />
                            </Link>
                        </div>

                        {/* Offcanvas Toggle Button for Mobile/Tablet */}
                        <button
                            className="btn btn-hamburger d-lg-none"
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasNavbar"
                            aria-controls="offcanvasNavbar"
                        >
                            <i className="bi bi-list"></i>
                        </button>

                        {/* Main Menu for Larger Screens */}
                        <div className="main-menu">
                            <ul className="menu-list d-flex">
                                <li>
                                    <Link to="/">Auction</Link>
                                </li>
                                <li>
                                    <Link to={`/blog/`}>About Us</Link>
                                </li>
                                <li>
                                    <Link to={`/blog/`}>FAQ</Link>
                                </li>
                                <li>
                                    <Link to={`/blog/`}>How It Works</Link>
                                </li>
                                <li>
                                    <Link to={`/blog/`}>Contact Us</Link>
                                </li>
                                {isAuthenticated() && (
                                    <>
                                        <li>
                                            {/* <Link to="/dashboard">My Account</Link> */}
                                        </li>
                                        <li
                                            style={{
                                                backgroundColor: "#e7fff1",
                                                borderRadius: "40%",
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            {/* <Link
                                                to="/payment"
                                                className="icon-wrapper"
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    textDecoration: "none",
                                                    color: "inherit",
                                                    width: "100%",
                                                }}
                                            >
                                                <img
                                                    src="/assets/images/bg/wallet.svg"
                                                    alt="wallet icon"
                                                    style={{
                                                        marginRight: "8px",
                                                        backgroundColor: "#32c36c",
                                                        borderRadius: "50%",
                                                    }}
                                                />
                                                <span style={{ fontSize: "16px", color: "#000" }}>
                                                    ₹ {walletAmount}
                                                </span>
                                            </Link> */}
                                        </li>
                                        <li class="dropdown">
                                            <button
                                                class="btn dropdown-toggle btn-dropdown "
                                                type="button"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                <img
                                                    src="\assets\images\bg\avatar.png"
                                                    alt="profile"
                                                    style={{ width: "1.875rem" }}
                                                />
                                            </button>
                                            <ul class="dropdown-menu">
                                                <li>
                                                    <a class="dropdown-item" href="/blog">
                                                        Dashboard
                                                    </a>
                                                </li>
                                                <li>
                                                    <a class="dropdown-item" href="/blog">
                                                        My Accounts
                                                    </a>
                                                </li>
                                                <li>
                                                    <a class="dropdown-item" href="/blog">
                                                        My Orders
                                                    </a>
                                                </li>
                                                <li>
                                                    <a class="dropdown-item" href="/blog">
                                                        Wallet
                                                    </a>
                                                </li>
                                                <li>
                                                    <a class="dropdown-item" href="/blog">
                                                        Settings
                                                    </a>
                                                </li>
                                                <li>
                                                    <a class="dropdown-item" href="/blog">
                                                        Help
                                                    </a>
                                                </li>
                                                <li>
                                                    <hr class="dropdown-divider" />
                                                </li>
                                                <li>
                                                    <a class="dropdown-item" onClick={handleLogout}>
                                                        Logout
                                                        <img
                                                            class="logout-icon"
                                                            src="\assets\images\icons\profile_logout.svg"
                                                            alt="logout"
                                                        />
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </>
                                )}
                                <ul className='authentication-style'>
                                    {!isAuthenticated() && (
                                        <>
                                            {location.pathname === "/login" && (
                                                <li>
                                                    <Link to="/" className="eg-btn btn--primary header-btn">
                                                        Signup
                                                    </Link>
                                                </li>
                                            )}
                                            {location.pathname === "/" && (
                                                <li>
                                                    <Link to="/login" className="eg-btn btn--primary header-btn">
                                                        Login
                                                    </Link>
                                                </li>
                                            )}
                                        </>
                                    )}
                                </ul>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* Offcanvas Navbar */}
                <div
                    className="offcanvas offcanvas-end"
                    tabIndex="-1"
                    id="offcanvasNavbar"
                    aria-labelledby="offcanvasNavbarLabel"
                >
                    <div className="offcanvas-header">
                        {/* <h5 className="offcanvas-title" id="offcanvasNavbarLabel">Menu</h5> */}
                        <button
                            type="button"
                            className="btn-close text-reset"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="offcanvas-body">
                        <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                            <li>
                                <Link to="/" className="nav-link border border-primary-subtle">
                                    Auction
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/how-it-works"
                                    className="nav-link border border-primary-subtle"
                                >
                                    How It Works
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/about-us"
                                    className="nav-link border border-primary-subtle"
                                >
                                    About Us
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/faq"
                                    className="nav-link border border-primary-subtle"
                                >
                                    FAQ
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/contact"
                                    className="nav-link border border-primary-subtle"
                                >
                                    Contact Us
                                </Link>
                            </li>
                            {isAuthenticated() && (
                                <>
                                    <li>
                                        <Link
                                            to="/dashboard"
                                            className="nav-link border border-primary-subtle"
                                        >
                                            My Account
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/payment" className="nav-link walletColor">
                                            <img
                                                className=""
                                                src="/assets/images/bg/wallet.svg"
                                                alt="wallet icon"
                                                style={{ marginRight: "8px" }}
                                            />
                                            ₹ {walletAmount}
                                        </Link>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" style={{cursor: 'pointer'}} onClick={handleLogout}>
                                            Logout
                                            <img
                                                class="logout-icon"
                                                src="\assets\images\icons\profile_logout.svg"
                                                alt="logout"
                                            />
                                        </a>
                                    </li>
                                </>
                            )}
                            {!isAuthenticated() && (
                                <>
                                    {location.pathname === "/login" && (
                                        <li>
                                            <Link to="/" className="nav-link">
                                                Signup
                                            </Link>
                                        </li>
                                    )}
                                    {location.pathname === "/" && (
                                        <li>
                                            <Link to="/login" className="nav-link">
                                                Login
                                            </Link>
                                        </li>
                                    )}
                                </>
                            )}
                        </ul>
                    </div>
                </div>
            </header>
        </div>
    );
};
export default Header;