import React, { createContext, useContext, useState } from 'react';

const HighestBidderContext = createContext();

export const useHighestBidder = () => {
    return useContext(HighestBidderContext);
};

export const HighestBidderProvider = ({ children }) => {
    const [highestBidder, setHighestBidder] = useState('');

    return (
        <HighestBidderContext.Provider value={{ highestBidder, setHighestBidder }}>
            {children}
        </HighestBidderContext.Provider>
    );
};